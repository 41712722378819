import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin';

const CreateNewbornInvitation = () => {
	return (
		<Create resource="admin/newborn" redirect={'/invitation'} hasEdit={false} hasShow={false}>
			<SimpleForm dir="rtl">
				<Box>
					<Grid container style={{}} spacing={3}>
						<Grid xs={12}>
							<Typography variant="h5" gutterBottom textAlign="right">
								פרטי שולח/ת המתנה
							</Typography>
						</Grid>
						<Grid xs={6} sm={3}>
							<TextInput required label="שם פרטי" source="sender.first_name" fullWidth />
						</Grid>
						<Grid xs={6} sm={3}>
							<TextInput source="sender.last_name" label="שם משפחה" fullWidth />
						</Grid>
						<Grid xs={12} sm={3}>
							<TextInput source="sender.email" label="דואר אלקטרוני" fullWidth />
						</Grid>

						<Grid xs={12}>
							<Typography variant="h5" gutterBottom textAlign="right">
								פרטי מקבל/ת המתנה
							</Typography>
						</Grid>

						<Grid xs={6} sm={3}>
							<TextInput source="receiver.first_name" label="שם פרטי" fullWidth required />
						</Grid>
						<Grid xs={6} sm={3}>
							<TextInput source="receiver.last_name" label="שם משפחה" fullWidth />
						</Grid>
						<Grid xs={12} sm={3}>
							<TextInput source="receiver.email" label="דואר אלקטרוני" fullWidth />
						</Grid>
						<Grid xs={12} sm={3}>
							<TextInput
								source="receiver.phone"
								label="מספר טלפון (שאליו נשלח את המנוי)"
								fullWidth
								required
							/>
						</Grid>
						<Grid xs={12} sm={3}>
							<SelectInput
								fullWidth
								required
								source="type"
								choices={[
									{ id: 'newborn_1m', name: 'חודש אחד' },
									{ id: 'newborn_3m', name: '3 חודשים' },
									{ id: 'newborn_6m', name: '6 חודשים' },
								]}
							/>
						</Grid>
						<Grid xs={12} sm={12}>
							<TextInput source="text" label="כמה מילים ממך" multiline required fullWidth />
						</Grid>
						<Grid xs={12}>
							<TextInput source="video_url" label="video_url" fullWidth />
						</Grid>
					</Grid>
				</Box>
			</SimpleForm>
		</Create>
	);
};

export default CreateNewbornInvitation;
