import { Card, CardContent, CardHeader, CircularProgress, LinearProgress } from '@mui/material';

const MembersCard = ({
	memberStats,
	navigation,
	totalUsers,
	todayUsers,
	newUsers,
	groupSessionsStatsLoading,
	todaysEnrollments,
	totalPrivateSessionsToday,
	totalPrivateSessions,
}: {
	memberStats: any;
	navigation: (path: string) => void;
	totalUsers?: number;
	todayUsers?: { mobile: number; web: number };
	newUsers?: { mobile: number; web: number };
	groupSessionsStatsLoading: boolean;
	todaysEnrollments?: number;
	totalPrivateSessionsToday?: number;
	totalPrivateSessions?: number;
}) => {
	return (
		<Card
			style={{}}
			onClick={() => {
				navigation('/member?order=DESC&page=1&perPage=10&sort=is_new');
			}}
		>
			<CardHeader title="משתמשים" style={{ height: 10 }} />
			<CardContent style={{ overflowY: 'scroll' }}>
				{memberStats.loading ? (
					<CircularProgress />
				) : (
					<div>
						<table className="border-separate border-spacing-x-3 w-full">
							<tr style={{}}>
								<td style={{ textAlign: 'right', fontWeight: '600', fontSize: 20 }}>סך הכל</td>
								<td>
									<span className="text-2xl font-semibold">{totalUsers}</span>
								</td>
							</tr>
							<tr className="font-bold">
								<td />
								<td>מובייל</td>
								<td>ווב</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'right' }}>התחברו היום</td>
								<td>{<span>{`${todayUsers?.mobile}`}</span>}</td>
								<td>{<span>{`${todayUsers?.web}`}</span>}</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'right' }}>חדשים </td>
								<td>{<span>{`${newUsers?.mobile}`}</span>}</td>
								<td>{<span>{`${newUsers?.web}`}</span>}</td>
							</tr>
							<tr>
								<br />
							</tr>
							<tr className="font-bold">
								<td className="" />
								<td className="min-w-20">היום</td>
								<td>סה"כ</td>
							</tr>
							<tr>
								<td className="text-start font-bold">הרצאות וסדנאות</td>
								<td>{groupSessionsStatsLoading ? <LinearProgress /> : todaysEnrollments}</td>
							</tr>
							<tr>
								<td className="text-start font-bold">מפגשים פרטניים</td>
								<td>{totalPrivateSessionsToday}</td>
								<td>{totalPrivateSessions}</td>
							</tr>
						</table>
					</div>
				)}
			</CardContent>
		</Card>
	);
};

export default MembersCard;
