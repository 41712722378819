import { Box, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import {
	ArrayInput,
	BooleanInput,
	DateInput,
	ImageField,
	ImageInput,
	NumberInput,
	SelectArrayInput,
	SimpleFormIterator,
	TextInput,
} from 'react-admin';
import { GiCancel } from 'react-icons/gi';
import constants from '../../constants';

const GeneralCompanyInfo = ({
	udiFactors,
	record,
	refetch,
	onAddUdiFactorsButtonPress,
}: {
	udiFactors: Map<string, { value: number; updatedAt: Date; active: boolean }>;
	record: any;
	refetch: () => void;
	onAddUdiFactorsButtonPress: () => void;
}) => {
	return (
		<Grid container justifyContent="flex-start" spacing={2}>
			<Grid item xs={12} textAlign={'start'}>
				<TextInput label="שם חברה" source="english_name" />
			</Grid>

			<Box width="100%" />
			<Grid item sm={3} alignContent={'center'}>
				<ImageField source="logo" sx={{}} />
			</Grid>
			<Grid item sm={3} alignContent={'center'}>
				<TextInput source="logo_background" label="רקע לוגו" type="color" style={{ width: 150 }} />
			</Grid>
			<Grid item sm={3}>
				<ImageInput label="החלפת לוגו" source="picture">
					<ImageField source="src" />
				</ImageInput>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h6" textAlign={'start'}>
					מתחמים
				</Typography>
			</Grid>
			<Grid item sm={2}>
				<BooleanInput source="helping_hand" label="עזרה ראשונה (Helping hand)" />
			</Grid>
			<Grid item sm={2}>
				<BooleanInput label="הזמנת בן משפחה" fullWidth source="invite_family_members" />
			</Grid>
			<Grid item sm={2}>
				<BooleanInput label="תכנית פרואקטיבית?" fullWidth source="allow_proactive_app" />
			</Grid>
			<Grid item sm={2}>
				<BooleanInput label="פרגונים?" fullWidth source="pass_it_on" />
			</Grid>
			<Grid item sm={2}>
				<BooleanInput label="שיחות הכוונה?" fullWidth source="one_to_one_available" />
			</Grid>
			<Grid item sm={3} alignContent={'center'}>
				<SelectArrayInput
					style={{ marginTop: -10 }}
					fullWidth
					choices={[
						{ name: 'Dating', id: 'DATING' },
						{ name: 'Care Givers', id: 'CARE_GIVERS' },
						{ name: 'Cool Stuff', id: 'COOL_STUFF' },
						{ name: 'קשב וריכוז', id: 'ADHD' },
					]}
					source="imagebuttons_names"
				/>
			</Grid>

			<Box width="100%" />

			<Grid item xs={12}>
				<Typography variant="h6" textAlign={'start'}>
					מרחב בטוח
				</Typography>
			</Grid>
			<Grid item sm={2}>
				<BooleanInput source="safe_space" label="המרחב הבטוח" />
			</Grid>

			<Grid item sm={3}>
				<BooleanInput source="safe_space_for_family_members" label="הצגת מרחב בטוח לבני משפחה" />
			</Grid>
			<Box width="100%" />
			<Grid item sm={4}>
				<TextInput fullWidth source="safe_space_text_lng.he" label="טקסט מרחב בטוח" multiline />
			</Grid>
			<Grid item sm={4}>
				<TextInput fullWidth source="safe_space_text_lng.en" label="טקסט מרחב בטוח (אנ')" multiline />
			</Grid>

			<Box width="100%" />
			<Grid item xs={12}>
				<Typography variant="h6" textAlign={'start'}>
					טקסט פתיחה
				</Typography>
			</Grid>
			<Grid item sm={12}>
				<TextInput fullWidth source="hello_text" label="כותרת לטקסט פתיחה" />
			</Grid>
			<Grid item sm={12}>
				<TextInput fullWidth source="text" label="טקסט פתיחה" multiline />
			</Grid>
			<Grid item sm={2}>
				<BooleanInput label="שליחת טקסט פתיחה?" fullWidth source="send_welcome_text" />
			</Grid>

			<Box width="100%" />
			<Grid item sm={3}>
				<ArrayInput source="safe_space_prices" label="מחירי מרחב בטוח">
					<SimpleFormIterator
						disableClear
						fullWidth
						getItemLabel={(index: number) => <Typography width={60}>{`${index + 1}. `}</Typography>}
					>
						<NumberInput source="price" fullWidth />
					</SimpleFormIterator>
				</ArrayInput>
			</Grid>
			<Box width="100%" />
			<Grid item sm={2}>
				<DateInput source="subscription_start" label="תחילת המנוי" />
			</Grid>
			<Grid item sm={2}>
				<BooleanInput source="active" label="חברה פעילה" />
			</Grid>
			<Grid item sm={12}>
				<div className="text-start">
					{udiFactors ? (
						<table className="table-fixed border-separate border-spacing-x-5">
							<thead>
								<th className="text-start w-44 ">שם</th>
								<th className="text-start">ערך</th>
								<th className="text-start w-32"></th>
								<th className="text-start"></th>
							</thead>
							{Object.entries(udiFactors).map(([key, valueObj]) => (
								<tr className="h-8">
									<td className={`text-start text-sm ${!valueObj.active ? 'line-through' : ''}`}>
										{key ?? 0}
									</td>
									<td
										className={`text-start text-sm font-bold ${
											!valueObj.active ? 'line-through' : ''
										}`}
									>
										{valueObj.value}
									</td>
									<td dir="ltr" className="text-left text-xs">
										{moment(valueObj.updatedAt).fromNow()}
									</td>
									<td className="">
										<Button
											disabled={!valueObj.active}
											variant="text"
											onClick={async () => {
												axios.delete(
													`${constants.API_URL}/company/${record._id}/udi_deactivate_factor`,
													{
														data: { name: key },
													}
												);

												refetch();
											}}
										>
											<GiCancel />
										</Button>
									</td>
								</tr>
							))}
						</table>
					) : null}
					<div className="">
						<Button variant="contained" className="block" onClick={onAddUdiFactorsButtonPress}>
							עריכת פקטורים
						</Button>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};

export default GeneralCompanyInfo;
