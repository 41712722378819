import { Box, Grid } from '@mui/material';
import { ArrayInput, NumberInput, SimpleFormIterator, TextInput } from 'react-admin';

const MiluimForm = ({}: {}) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={3}>
				<NumberInput sx={{ marginTop: '20px' }} source="miluim.price" step={0.1} label="מחיר למפגש" fullWidth />
			</Grid>

			<Grid item xs={3}>
				<NumberInput
					sx={{ marginTop: '20px' }}
					fullWidth
					source="miluim.number_of_sessions"
					label="מספר מפגשים"
				/>
			</Grid>
			<Box width="100%" />
			<Grid item xs={4}>
				<ArrayInput source="miluim.emails" label="אימיילים">
					<SimpleFormIterator inline disableClear fullWidth>
						<TextInput source="" helperText={false} fullWidth />
					</SimpleFormIterator>
				</ArrayInput>
			</Grid>
		</Grid>
	);
};

export default MiluimForm;
