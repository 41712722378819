import { Card, CardContent, CardHeader, CircularProgress } from '@mui/material';

import { RandomPassItOnMessage } from './useDashboard';

const RandomPassItOnCard = ({
	fetchAgain,
	data,
	isFetching,
	isTVMode,
}: {
	fetchAgain: () => void;
	data?: RandomPassItOnMessage[];
	isFetching?: boolean;
	isTVMode?: boolean;
}) => {
	return (
		<Card style={{}}>
			<CardHeader
				style={{ paddingBottom: 0 }}
				title="פרגונים"
				action={
					<div style={{ width: 64 }}>
						{isFetching ? (
							<CircularProgress size={16} />
						) : // <Button onClick={fetchAgain}>
						// 	<BiRefresh size={20} />
						// </Button>
						null}
					</div>
				}
			/>
			<CardContent style={{ height: 300, overflowY: 'scroll' }}>
				<table
					className="border-separate border-spacing-y-1 border-spacing-x-0"
					style={{ tableLayout: 'fixed', fontSize: isTVMode ? 20 : 16 }}
				>
					<thead className="text-start bg-gray-200">
						<th className="text-start">פרגון</th>
						<th className="text-center">חברה</th>
					</thead>
					{data?.map(({ text, company }) => (
						<tr className="">
							<td className="w-full text-start">{text}</td>
							<td className={`text-center text-${isTVMode ? 'lg' : 'sm'}`}>{company}</td>
						</tr>
					))}
				</table>
			</CardContent>
		</Card>
	);
};

export default RandomPassItOnCard;
