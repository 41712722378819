import { Button, TextField as MuiTextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	Datagrid,
	FunctionField,
	Identifier,
	List,
	ReferenceField,
	TextField,
	useListContext,
	useListController,
	useRefresh,
} from 'react-admin';
import { CgNotes, CgTime } from 'react-icons/cg';
import { FaDownload } from 'react-icons/fa';
import constants from '../../../constants';
import { downloadEncodedCSV, formatPrice } from '../../../utils/functions';
const MonthlySessionsStats = () => {
	const properties = [
		{ field: 'helpingHand', name: 'עזרה ראשונה' },
		{ field: 'consultationSessions', name: 'הכוונה' },
		{ field: 'oneToOneSessions', name: 'אחד על אחד' },
		{ field: 'groupSessions', name: 'הרצאות וסדנאות' },
		{ field: 'safe_space', name: 'מרחב בטוח' },
	];
	const [date, setDate] = useState(new Date());

	const Aside = () => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'ILS',
		});
		const context = useListContext();
		const { data } = context;

		const total = data?.reduce((curr, next) => (curr += next.total?.price), 0);
		const totalGroupSessions = data?.reduce((curr, next) => (curr += next.groupSessions?.price), 0);
		const totalConsultations = data?.reduce((curr, next) => (curr += next.consultationSessions?.price), 0);

		return (
			<Box paddingBottom={1} display={'flex'} flexDirection="row" justifyContent={'center'} gap={2}>
				<Typography>
					{`סה"כ לתשלום לתקופה הנבחרת: `}
					<Typography display="inline" fontWeight={'bold'}>{`${formatter.format(total) ?? '-'}`}</Typography>
				</Typography>
				<Typography>
					{`הרצאות וסדנאות: `}
					<Typography
						display="inline"
						fontWeight={'bold'}
					>{`${formatter.format(totalGroupSessions) ?? '-'}`}</Typography>
				</Typography>
				<Typography>
					{`הכוונה: `}
					<Typography
						display="inline"
						fontWeight={'bold'}
					>{`${formatter.format(totalConsultations) ?? '-'}`}</Typography>
				</Typography>
			</Box>
		);
	};
	const month = moment(date).month();
	const refresh = useRefresh();
	const year = moment(date).year();
	const [isLoading, setIsLoading] = useState(false);
	const updateMonthlySessions = async () => {
		try {
			setIsLoading(true);
			await axios.post(
				`${constants.API_URL}/instructor/sessions/stats/update`,
				{},
				{ params: { filter: { month, year } } },
			);
			refresh();
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		// updateMonthlySessions();
	}, [month, year]);
	const { data } = useListController({ filter: { month, year } });
	return (
		<Box marginTop={2}>
			<Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignSelf={'center'} gap={1}>
				<MuiTextField
					style={{ alignSelf: 'center' }}
					value={moment(date).format('YYYY-MM')}
					type="month"
					onChange={(e) => {
						setDate(new Date(e.target.value));
					}}
				/>
				<Button style={{ alignSelf: 'center' }} onClick={updateMonthlySessions}>
					עדכון נתונים
				</Button>
			</Box>
			<List
				actions={
					<div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
						<Button
							style={{ flexDirection: 'row', alignItems: 'center', gap: 8, marginBottom: 8 }}
							onClick={(e) => {
								downloadEncodedCSV(
									data
										.filter((x) => x.total.price)
										.map((row) => ({ name: row.name, total: formatPrice(row.total.price) }))
										.sort((a, b) => a.name.localeCompare(b.name)),
									['name', 'total'],
									`monthly_sessions_${month}_${year}.csv`,
								);
								e.preventDefault();
								return;
							}}
						>
							Export
							<FaDownload />
						</Button>
						<Aside />
					</div>
				}
				filter={{ month, year }}
				perPage={9999}
			>
				<Datagrid
					isLoading={isLoading}
					bulkActionButtons={false}
					rowClick={(id: Identifier) =>
						`/${'instructor/sessions/stats/by_host'}/${id}?month=${month}&year=${year}`
					}
				>
					<ReferenceField source="_id" reference="instructor/all" sortBy="name">
						<TextField source="name" />
					</ReferenceField>
					<FunctionField label="עוסק" render={(record: any) => record.licensed_dealer && 'ע. מורשה'} />
					{properties.map((prop) => (
						<FunctionField
							key={prop.field}
							render={(record: any) =>
								record[prop.field].count ? (
									<Tooltip
										title={
											record[prop.field].info ? (
												<div style={{ whiteSpace: 'pre-line' }}>{record[prop.field].info}</div>
											) : null
										}
										style={{ whiteSpace: 'pre-line' }}
									>
										<Typography display={'inline'} whiteSpace={'pre-wrap'}>
											{`${formatPrice(record[prop.field].price)}₪`}
											<Typography
												display={'inline'}
												variant="caption"
											>{` (${record[prop.field].count})`}</Typography>
										</Typography>
									</Tooltip>
								) : null
							}
							label={prop.name}
						/>
					))}
					<FunctionField render={(record: any) => (record.is_psychologist ? 'פסיכולוג.ית' : '')} />
					<FunctionField
						label="תוספת חד פעמית"
						render={(record: any) => (record.fixedPayment ? `${record.fixedPayment} ₪` : null)}
					/>
					<FunctionField
						label="ניכוי מעמ"
						dir="ltr"
						render={(record: any) =>
							record.vat_refund.price ? `${formatPrice(record.vat_refund.price)}₪` : ''
						}
					/>
					<FunctionField
						render={(record: any) =>
							record.notes || record.fixedPayment ? (
								<Tooltip
									title={
										<div
											style={{ whiteSpace: 'pre-line' }}
										>{`${record.notes ? `הערות:\n${record.notes}` : ''}${
											record.notes && record.fixedPayment ? '\n\n' : ''
										}${record.fixedPayment ? `תשלום חד פעמי: ${record.fixedPayment} ₪` : ''}`}</div>
									}
									style={{ whiteSpace: 'pre-line', zIndex: 10 }}
								>
									<span style={{ color: 'orangered', fontSize: 16 }}>
										<CgNotes />
									</span>
								</Tooltip>
							) : null
						}
					/>
					<FunctionField
						textAlign="right"
						label="עלות כוללת"
						render={(record: any) => `${formatPrice(record?.total.price)} ₪`}
					/>
					<FunctionField
						align="center"
						textAlign="right"
						label="עדכון אחרון"
						render={(record: any) => (
							<Tooltip
								title={moment(record.updatedAt).locale('he-IL').fromNow()}
								style={{ whiteSpace: 'pre-line', zIndex: 10 }}
							>
								<span
									style={{
										color: moment().diff(record.updatedAt, 'hour') > 1 ? 'red' : 'black',
										textAlign: 'center',
									}}
								>
									<CgTime />
								</span>
							</Tooltip>
						)}
					/>
				</Datagrid>
			</List>
		</Box>
	);
};

export default MonthlySessionsStats;
