import axios, { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

function useFetch<T>(
	url: string,
	options: AxiosRequestConfig<any> | undefined = undefined,
	skip = false,
	pollingInterval: number | null | undefined = undefined
) {
	const [data, setData] = useState<T>();
	const [loading, setLoading] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [error, setError] = useState<string>();

	const pollingRef = useRef<NodeJS.Timeout | null>(null);
	const optionsJson = JSON.stringify(options);
	const fetchData = useCallback(async () => {
		if (skip) return;

		setIsFetching(true);
		if (!data) setLoading(true);
		const source = axios.CancelToken.source();

		try {
			const res = await axios(url, { cancelToken: source.token, ...options });
			if (res.data) {
				setData(res.data);
			}
		} catch (err: any) {
			if (!axios.isCancel(err)) {
				setError(err?.response?.data || err.message);
			}
		} finally {
			setLoading(false);
			setIsFetching(false);
		}

		// Return the cancel function to be called on unmount or on change of dependencies
		return () => {
			source.cancel();
		};
	}, [url, skip, optionsJson]);

	useEffect(() => {
		if (!skip) {
			const cancelFetch = fetchData();
			return () => {
				if (cancelFetch instanceof Function) {
					cancelFetch();
				}
			};
		}
	}, [fetchData, skip]);

	useEffect(() => {
		if (pollingInterval && !skip) {
			pollingRef.current = setInterval(() => {
				fetchData();
			}, pollingInterval);

			return () => {
				if (pollingRef.current) {
					clearInterval(pollingRef.current);
					pollingRef.current = null;
				}
			};
		}
	}, [fetchData, pollingInterval, skip]);

	const clearData = () => {
		setData(undefined);
	};

	const fetchAgain = async () => {
		return await fetchData();
	};

	return { data, loading, error, fetchAgain, clearData, isFetching };
}

export default useFetch;
