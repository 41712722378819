import axios, { AxiosError, AxiosResponse } from 'axios';
import { stringify } from 'querystring';
import simpleRestProvider from 'ra-data-simple-rest';
import { CreateParams, DataProvider, fetchUtils, UpdateParams } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';
import constants from '../constants';

axios.interceptors.response.use(
	(a: AxiosResponse) => {
		return a;
	},
	(error: AxiosError) => {
		if (error.response?.status === 401) {
			return refreshTheToken(error);
		} else throw error;
	},
);
export const refreshTheToken = async (error: AxiosError) => {
	const originalRequest: any = error.config;
	if (error.response?.status === 401 && !originalRequest._retry) {
		originalRequest._retry = true;
		try {
			const refresh_token = localStorage.getItem('refresh_token');
			const refresh_response = await axios.post(constants.API_URL + 'user/refresh', {
				refresh_token,
			});

			if (refresh_response.status === 201) {
				// 1) put token to LocalStorage
				localStorage.setItem('token', refresh_response.data);
				localStorage.setItem('token_exp', (new Date().getTime() + 4 * 60 * 1000).toString());
				// 2) Change Authorization header
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + refresh_response.data;

				// 3) return originalRequest object with Axios.
				return axios({
					...originalRequest,
					data: originalRequest.data ? JSON.parse(originalRequest.data) : undefined,
					headers: { Authorization: 'Bearer ' + refresh_response.data },
				});
			}
		} catch (error) {}
	}
};
export const httpClient = (url: any, options: fetchUtils.Options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' });
	}
	const token = localStorage.getItem('token');
	options.user = {
		authenticated: Boolean(token),
		// use the token from local storage
		token: `Bearer ${token}`,
	};
	axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

	return axios(url, {
		method: options.method,
		data: options.body,
		headers: { 'Content-Type': 'application/json' },
	}).then(async (res) => ({
		status: res.status,
		json: res.data,
		headers: new Headers(res.headers),
		body: res.data,
	}));
};
const dataProvider = simpleRestProvider(constants.API_URL, httpClient);
const myDataProvider: DataProvider = {
	...dataProvider,
	getList: async (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify(params.filter),
		};
		const url = `${constants.API_URL}/${resource}?${stringify(query)}`;

		const { headers, json } = await httpClient(url);
		return {
			data: (json.data || json)?.map((resource_1: any) => ({ ...resource_1, id: resource_1._id })),
			total: parseInt(headers?.get('content-range')?.split('/').pop() || '0', 10),
		};
	},
	getOne: (resource, params) =>
		httpClient(`${constants.API_URL}/${resource}/${params.id}`).then(({ json }) => ({
			data: { ...json, id: json._id },
		})),

	getMany: async (resource, params) => {
		const query = {
			filter: JSON.stringify({ _id: params.ids }),
		};
		const url = `${constants.API_URL}/${resource}?${stringify(query)}`;
		const { json } = await httpClient(url);

		return {
			data: (json.data || json)?.map((resource: any) => ({ ...resource, id: resource._id })),
		};
	},

	getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		params = { ...params };
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify({
				...params.filter,
				[params.target]: params.id,
			}),
		};
		const url = `${constants.API_URL}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ headers, json }) => ({
			data: json.map((resource: any) => ({ ...resource, id: resource._id })),
			total: parseInt(headers?.get('content-range')?.split('/').pop() || '0', 10),
		}));
	},

	update: async (resource: string, params: UpdateParams<any>) => {
		if (resource === 'proactive/item') {
			const formData = createFormData(params.data);

			httpClient(`${constants.API_URL}${resource}/${params.id}`, {
				method: 'PUT',
				body: formData,
			}).then(({ json }) => ({ data: { ...json, id: json._id } }));
		}
		const pic = params.data.picture || params.data.image;

		if (!pic?.rawFile) {
			const { data } = await dataProvider.update(resource, params);
			return { data: { ...data, id: data.id } };
		}

		const { data } = await dataProvider.update(resource, {
			data: { ...params.data, picture: await getTransformedImage(pic, params.data.name || params.data.title) },
			id: params.id,
			previousData: params.data,
		});

		return { data: { ...data, id: data.id } };
	},

	create: async (resource: string, params: CreateParams<any>) => {
		const pic = params.data.picture || params.data.image;
		if (!pic) {
			const { data } = await dataProvider.create(resource, params);
			return { data: { ...data, id: data.id } };
		}

		const { data } = await dataProvider.create(resource, {
			data: { ...params.data, picture: await getTransformedImage(pic, params.data.name || params.data.title) },
		});
		return { data: { ...data, id: data.id } };
	},
};

const getTransformedImage = async (file: { rawFile: Blob }, name: string) => {
	const newPicture = file;
	if (newPicture) {
		const base64Picture = await convertFileToBase64(newPicture);
		return {
			src: base64Picture,
			title: `${name || uuidv4()}`,
		};
	}
};
const convertFileToBase64 = (file: { rawFile: Blob }) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;

		reader.readAsDataURL(file.rawFile);
	});

type FormDataParams<T> = {
	[K in keyof T]?: T[K] | { rawFile: File };
};

const createFormData = <T>(params: FormDataParams<T>): FormData => {
	const formData = new FormData();

	for (const key in params) {
		if (params.hasOwnProperty(key)) {
			const value = params[key];
			if ((value as any)?.rawFile) {
				formData.append(key, (value as any).rawFile);
			} else if (value !== undefined && value !== null) {
				formData.append(key, String(value));
			}
		}
	}
	return formData;
};

export default myDataProvider;
