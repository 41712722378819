import {
	BooleanInput,
	Create,
	ImageField,
	ImageInput,
	NumberInput,
	ReferenceArrayInput,
	SelectArrayInput,
	SimpleForm,
	TextInput,
	useNotify,
} from 'react-admin';
import { getLocalizedErrorMessage } from '../../utils/functions';
import { Personalization } from '../Meeting/MeetingCreate';
import { useState } from 'react';

const MediaLinkCreate = () => {
	const notify = useNotify();
	const [ignoreAge, setIgnoreAge] = useState(false);

	return (
		<Create
			resource="media-links"
			mutationOptions={{
				onError: (err: any) => {
					notify(getLocalizedErrorMessage(err), { type: 'error' });
				},
			}}
		>
			<SimpleForm>
				<TextInput source="title" label="שם" dir="rtl" style={{ direction: 'rtl' }} fullWidth />
				<TextInput source="sub_title" label="תת כותרת" dir="rtl" style={{ direction: 'rtl' }} fullWidth />
				<TextInput source="link" fullWidth />

				<ImageInput source="picture" label="Logo">
					<ImageField source="src" />
				</ImageInput>
				<ReferenceArrayInput reference="vod/categories/new" source="category">
					<SelectArrayInput optionText={'hebrew_name'} />
				</ReferenceArrayInput>
				<NumberInput source="priority" label="קדימות" fullWidth />
				<BooleanInput source="visible" label="זמין" fullWidth defaultChecked={true} />
				<TextInput source="type" />
				<Personalization setIgnoreAge={setIgnoreAge} ignoreAge={ignoreAge} />
			</SimpleForm>
		</Create>
	);
};

export default MediaLinkCreate;
