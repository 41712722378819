import { Button } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Show, SimpleShowLayout, TextField, useShowController } from 'react-admin';
import constants from '../../constants';

const CompanyShow = () => {
	const { record } = useShowController();
	const [winner, setWinner] = useState<{ first_name: string; last_name: string } | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	useEffect(() => {}, [winner]);
	return (
		<Show title={<span className="font-bold">{record?.english_name}</span>}>
			<SimpleShowLayout>
				<TextField source="english_name" />
				<div className="text-center flex justify-center h-[200px] items-center">
					{winner ? (
						<h1 className="text-5xl font-bold">{`${winner.first_name} ${winner.last_name}`}</h1>
					) : isLoading ? (
						<img alt="loading" style={{ height: 200 }} src={'transparent-logo.gif'} />
					) : null}
				</div>
				<div>
					<Button
						disabled={isLoading}
						color={isError ? 'error' : 'primary'}
						onClick={async () => {
							try {
								setWinner(null);
								const response = await axios.get(
									constants.API_URL + '/company/' + record?._id + '/lottery',
								);
								setIsLoading(true);
								if (response.data?.length) {
									setTimeout(() => {
										setWinner(response.data[0]);
										setIsLoading(false);
									}, 4000);
								} else {
									setIsLoading(false);
								}
							} catch (error) {
								setIsError(true);
							}
						}}
					>
						הגרלה
					</Button>
					<Button
						onClick={async () => {
							setWinner(null);
							setIsError(false);
							setIsLoading(false);
						}}
					>
						איפוס
					</Button>
				</div>
			</SimpleShowLayout>
		</Show>
	);
};

export default CompanyShow;
