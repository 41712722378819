import { BooleanInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const MediaCategoryEdit = () => {
	return (
		<Edit resource="vod/category">
			<SimpleForm>
				<TextInput source="hebrew_name" />
				<TextInput source="english_name" />
				<BooleanInput source="visible" />
				<NumberInput source="priority" label="עדיפות" />
				<BooleanInput source="promoted" label="מקודם" />
				<TextInput source="type" />
				<ReferenceInput source="parent_category" reference="vod/categories/all" resource="vod/category">
					<SelectInput optionText={'hebrew_name'} />
				</ReferenceInput>
			</SimpleForm>
		</Edit>
	);
};

export default MediaCategoryEdit;
