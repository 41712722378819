import React, { useState } from 'react';
import { FileInput, InputProps, useInput } from 'react-admin';

interface VideoUploadInputProps extends InputProps {
	source: string;
}

const VideoUploadInput: React.FC<VideoUploadInputProps> = ({ source, ...props }) => {
	const { field } = useInput({ source });
	const [videoPreview, setVideoPreview] = useState<string | null>(null);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			field.onChange(file);
			setVideoPreview(URL.createObjectURL(file));
		}
	};

	return (
		<div>
			<FileInput source={source} label="Upload Video" accept="video/*" onChange={handleFileChange} {...props} />
			{videoPreview && (
				<video width="320" height="240" controls>
					<source src={videoPreview} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			)}
		</div>
	);
};

export default VideoUploadInput;
