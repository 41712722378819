import { BooleanInput, Edit, ImageField, ImageInput, NumberInput, SimpleForm, TextField, TextInput } from 'react-admin';

const ArticleEdit = () => {
	return (
		<Edit>
			<SimpleForm width={'100%'}>
				<TextField source="id" mb={5} />
				<TextInput source="title" label="שם" dir="rtl" style={{ direction: 'rtl' }} fullWidth />
				<TextInput source="sub_title" label="תת כותרת" dir="rtl" style={{ direction: 'rtl' }} fullWidth />
				<TextInput source="html" label="HTML" fullWidth dir="ltr" multiline />
				<TextInput source="text" label="link" fullWidth dir="ltr" />

				<ImageInput source="picture" label="Logo">
					<ImageField source="src" />
				</ImageInput>

				<TextInput source="category" label="קטגוריה" />
				<TextInput source="description" label="תיאור" fullWidth multiline />
				<NumberInput source="priority" label="קדימות" fullWidth />
				<BooleanInput source="visible" label="זמין" fullWidth defaultChecked={true} />
			</SimpleForm>
		</Edit>
	);
};
export default ArticleEdit;
