import { Button, Dialog } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import {
	BooleanField,
	Datagrid,
	FunctionField,
	ImageField,
	List,
	NumberField,
	TextField,
	useNotify,
	useRefresh,
} from 'react-admin';
import { SubmitHandler, useForm } from 'react-hook-form';
import constants from '../../constants';
import { downloadEncodedCSV, getLocalizedErrorMessage } from '../../utils/functions';
import moment from 'moment';

type AwardPrizeInputs = { email: string; updatePoints: boolean; prizeId: string };

const AwardModal = ({
	open,
	onClose,
	title,
	onSuccess,
	prizeId,
}: {
	prizeId: string;
	open: boolean;
	onClose: () => void;
	title: string;
	onSuccess: () => void;
}) => {
	const { register, handleSubmit } = useForm<AwardPrizeInputs>();

	const notify = useNotify();
	const award: SubmitHandler<AwardPrizeInputs> = async (data) => {
		try {
			await axios.post(`${constants.API_URL}/prize/${prizeId}/award`, {
				email: data.email,
				updatePoints: Boolean(data.updatePoints),
			});
			notify('הפרס נשלח בהצלחה', { type: 'success' });
			onSuccess();
		} catch (error) {
			notify(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
		}
	};
	return (
		<Dialog open={open} onClose={onClose}>
			<div className="p-4 ">
				<h1>{title}</h1>
				<div className="flex flex-row gap-4 items-center" dir="rtl">
					<input className="outline-none border-b" placeholder="מייל משתמש" {...register('email')} />
					<span className="contents">
						<input id="updatePoints" type="checkbox" {...register('updatePoints')} />
						<label className="text-sm" htmlFor="updatePoints">
							שימוש בנקודות?
						</label>
					</span>
					<Button onClick={handleSubmit(award)}>הענקת פרס</Button>
				</div>
			</div>
		</Dialog>
	);
};
const PrizeList = () => {
	const [isAwardModalVisible, setIsAwardModalVisible] = useState(false);
	const showAwardModal = () => setIsAwardModalVisible(true);

	const [selectedPrize, setSelectedPrize] = useState<{ title: string; _id: string } | null>(null);
	const refresh = useRefresh();

	const hideAwardModal = () => {
		setIsAwardModalVisible(false);
	};

	const exporter = async (prizes: any[]) => {
		const prizesToExport = prizes.map((prize: any) => ({
			title_he: prize?.title?.he,
			title_en: prize?.title?.en,
			subTitle_he: prize?.subTitle?.he,
			subTitle_en: prize?.subTitle?.en,
			points: prize?.points,
			imageUrl: prize?.imageUrl,
			description_he: prize?.description?.he,
			description_en: prize?.description?.en,
		}));

		downloadEncodedCSV(
			prizesToExport,
			[
				'title_he',
				'title_en',
				'subTitle_he',
				'subTitle_en',
				'points',
				'imageUrl',
				'description_he',
				'description_en',
			],
			`prizes_${moment().format('YYYY_MM_DD')}`
		);
	};
	return (
		<>
			{selectedPrize?._id ? (
				<AwardModal
					open={isAwardModalVisible}
					onClose={hideAwardModal}
					onSuccess={async () => {
						hideAwardModal();
						refresh();
					}}
					prizeId={selectedPrize?._id}
					title={`שליחת פרס ${selectedPrize?.title}`}
				/>
			) : null}
			<List exporter={exporter}>
				<Datagrid rowClick="edit">
					<TextField source="title.he" />
					<NumberField source="points" />
					<FunctionField
						label="קופונים"
						render={(record: any) => {
							const redeemedCount = record.coupons.filter(
								(coupon: { redeemed: boolean }) => coupon.redeemed
							)?.length;
							return `${redeemedCount}/${record.coupons?.length}`;
						}}
					/>
					<BooleanField source="visible" />
					<TextField source="baseLink" />
					<ImageField source="thumbnail" textAlign="center" />
					<ImageField source="imageUrl" label="תמונה" textAlign="center" />
					<FunctionField
						render={(record: any) => {
							const redeemedCount = record.coupons.filter(
								(coupon: { redeemed: boolean }) => coupon.redeemed
							)?.length;

							if (redeemedCount === record.coupons.length) return null;
							return (
								<Button
									onClick={(e) => {
										e.stopPropagation();
										showAwardModal();
										setSelectedPrize({ _id: record._id, title: record.title.he });
										return;
									}}
								>
									שליחת פרס
								</Button>
							);
						}}
					/>
				</Datagrid>
			</List>
		</>
	);
};

export default PrizeList;
