import { BooleanInput, Create, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from 'react-admin';

const PrizeCreate = () => {
	return (
		<Create>
			<SimpleForm>
				<div className="w-full">
					<div className="gap-4 flex flex-row">
						<TextInput source="title.he" className="inline" />
						<TextInput source="title.en" className="inline" />
					</div>

					<div className="gap-4 flex flex-row">
						<TextInput source="subTitle.he" className="inline" />
						<TextInput source="subTitle.en" className="inline" />
					</div>
					<div className="gap-4 flex flex-row">
						<TextInput source="description.he" className="inline" />
						<TextInput source="description.en" className="inline" />
					</div>
					<div className="gap-4 flex flex-row">
						<NumberInput source="points" className="inline" />
						<TextInput source="imageUrl" className="inline" fullWidth />
						<TextInput source="thumbnail" className="inline" fullWidth />
						<TextInput source="baseLink" className="inline" fullWidth />
					</div>
					<ImageInput source="image" accept={[`image/*`]}>
						<ImageField source="src" />
					</ImageInput>
					<div className="gap-4 flex flex-row">
						<BooleanInput
							label="שרשור קוד הקופון לURL"
							source="isURLCoupon"
							helperText="במידה וכן, הקישור שיישלח למשתמש יהיה בפורמט baseLink+code. במידה ולא, הקישור יהיה לbaseLink וקוד הקופון ייכתב כטקסט בגוף המייל"
						/>
						<NumberInput source="order" className="inline" label="סדר" />
					</div>
				</div>
				<BooleanInput source="visible" />
			</SimpleForm>
		</Create>
	);
};

export default PrizeCreate;
