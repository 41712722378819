import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useState } from 'react';
import {
	BooleanInput,
	DateTimeInput,
	Edit,
	FormDataConsumer,
	ImageField,
	ImageInput,
	NumberInput,
	ReferenceArrayInput,
	SelectArrayInput,
	SimpleForm,
	TextInput,
	useEditController,
} from 'react-admin';

import AsideChangeLog from '../AsideChangeLog';
import SelectMeetingService from '../SelectMeetingService';
import { Personalization } from './MeetingCreate';

const MeetingEdit = (props: any) => {
	const [ignoreAge, setIgnoreAge] = useState(false);

	const transform = useCallback(
		(data: any) => {
			return {
				...data,

				age: ignoreAge ? { to: null, from: null } : data.age,
			};
		},
		[ignoreAge],
	);

	const { record } = useEditController();
	// const initServiceId = ;

	return (
		<Edit transform={transform} aside={<AsideChangeLog items={[]} />} mutationMode="pessimistic">
			<SimpleForm width={'100%'}>
				<Grid container width={'100%'} spacing={2} paddingTop={2}>
					<SelectMeetingService source="service" initServiceId={record?.service?._id} />
					<Grid item xs={5}>
						<TextInput source="title" fullWidth />
					</Grid>
					<Grid item xs={5}>
						<TextInput source="english_title" fullWidth />
					</Grid>
					<Grid item xs={5}>
						<TextInput source="sub_title.he" fullWidth />
					</Grid>
					<Grid item xs={5}>
						<TextInput source="sub_title.en" fullWidth />
					</Grid>
					<Grid item xs={5}>
						<TextInput source="description_lng.he" multiline fullWidth label="תיאור בעברית" />
					</Grid>
					<Box width={'100%'} />
					<Grid item xs={5}>
						<TextInput source="description_lng.en" multiline fullWidth label="תיאור באנגלית" dir="ltr" />
					</Grid>
					<Box width="100%" />

					<Grid item md={2}>
						<DateTimeInput source="time" fullWidth />
					</Grid>
					<Box width="100%" />
					<Grid item md={2}>
						<NumberInput source="price" label="מחיר" fullWidth />
					</Grid>
					<Grid item md={2}>
						<NumberInput source="price_for_members" label="מחיר למנויי גרופהאג" fullWidth />
					</Grid>
					<Grid item md={2}>
						<BooleanInput source="registration_limit_on" label={'הגבלת מספר משתתפים'} fullWidth />
					</Grid>
					<Grid item md={2}>
						<FormDataConsumer<{ registration_limit_on: boolean }>>
							{({ formData, ...rest }) =>
								formData.registration_limit_on && (
									<NumberInput source="max_register" fullWidth label="הגבלת מספר משתתפים" />
								)
							}
						</FormDataConsumer>
					</Grid>

					<Grid item md={1}>
						<NumberInput source="priority" fullWidth />
					</Grid>

					<Personalization setIgnoreAge={setIgnoreAge} ignoreAge={ignoreAge} />
					<Grid item md={2}>
						<BooleanInput source="should_record" label="מפגש מוקלט" fullWidth />
					</Grid>
					<Grid item xs={12}>
						<ImageInput source="image">
							<ImageField source="image" />
						</ImageInput>
					</Grid>
					<Box width="100%" />
					<Typography variant="h5" display={'block'}>
						מנהלה
					</Typography>
					<Box width="100%" />
					<Grid item xs={12} lg={3}>
						<ReferenceArrayInput reference="vod/categories" source="extra.vod_category">
							<SelectArrayInput optionText={'hebrew_name'} label="להעלות הקלטה לקטגוריה" fullWidth />
						</ReferenceArrayInput>
					</Grid>
					<Grid item xs={12} lg={2}>
						<BooleanInput source="extra.zoom_test_required" label="צריך לעלות לטסט" />
					</Grid>
					<Grid item xs={12} lg={2}>
						<BooleanInput source="extra.zoom_join_required" label="צריך לעלות למפגש" />
					</Grid>
					<Grid item xs={7}>
						<TextInput source="extra.text" label="הערות" fullWidth multiline />
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	);
};

export default MeetingEdit;
