import { useState } from 'react';
import {
	BooleanInput,
	Create,
	ImageField,
	ImageInput,
	NumberInput,
	ReferenceArrayInput,
	SelectArrayInput,
	SimpleForm,
	TextField,
	TextInput,
} from 'react-admin';
import { Personalization } from '../Meeting/MeetingCreate';

const VodCreate = () => {
	const [ignoreAge, setIgnoreAge] = useState(false);
	const transform = (data: any) => ({
		...data,
		age: ignoreAge ? { to: null, from: null } : data.age,
	});
	return (
		<Create transform={transform}>
			<SimpleForm width={'100%'}>
				<TextField source="id" mb={5} />
				<TextInput source="title" label="שם" dir="rtl" style={{ direction: 'rtl' }} fullWidth />
				<TextInput source="sub_title" label="תת כותרת" dir="rtl" style={{ direction: 'rtl' }} fullWidth />
				<TextInput source="videoUrl" label="קישור לוידאו" fullWidth dir="ltr" />

				<ImageInput source="picture" label="Logo">
					<ImageField source="src" />
				</ImageInput>
				<ReferenceArrayInput reference="vod/categories/all" source="category">
					<SelectArrayInput optionText={'hebrew_name'} />
				</ReferenceArrayInput>
				<Personalization setIgnoreAge={setIgnoreAge} ignoreAge={ignoreAge} />

				<TextInput source="description" label="תיאור" fullWidth multiline />
				<NumberInput source="priority" label="קדימות" fullWidth />
				<BooleanInput source="visible" label="זמין" fullWidth />
			</SimpleForm>
		</Create>
	);
};
export default VodCreate;
