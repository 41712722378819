import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import { PassItOnCompanyStats } from './useDashboard';

const PassItOnStatsCard = ({ stats, totalCount, totalChange }: Partial<PassItOnCompanyStats>) => {
	return (
		<Card style={{}}>
			<CardHeader style={{ paddingBottom: 0 }} title="פרגונים" />
			<CardContent style={{ height: 300, overflowY: 'scroll' }}>
				<table style={{ tableLayout: 'fixed' }}>
					<thead className="text-start bg-gray-200 text-sm">
						<th className="text-start ">חברה</th>
						<th className="text-center">מס' פרגונים</th>
						<th className="text-center pe-4">היום</th>
					</thead>
					<tr className="font-bold bg-gray-100">
						<td className="text-start text-sm w-full">סה"כ</td>
						<td className="min-w-24 text-center">
							<div className="flex flex-row justify-center items-center gap-2">{totalCount}</div>
						</td>
						<td>
							{totalChange ? (
								<span
									dir="ltr"
									className="text-[12px] font-medium text-green-800 pl-4"
								>{`+${totalChange}`}</span>
							) : (
								``
							)}
						</td>
					</tr>
					{stats?.map((company) => (
						<tr>
							<td className="text-start text-sm w-full line-clamp-1">{company.companyName}</td>
							<td className="min-w-24 text-center">{company.currentCount}</td>
							<td dir="ltr" className="min-w-8 text-center pl-4 text-[12px] font-medium text-green-800">
								{company.change ? `+${company.change}` : ''}
							</td>
						</tr>
					))}
				</table>
			</CardContent>
		</Card>
	);
};

export default PassItOnStatsCard;
