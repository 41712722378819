import { Button, Dialog, Input } from '@mui/material';
import { Reducer, useEffect, useReducer } from 'react';

const FactorsModal = ({
	onFactorUpdate,
	open,
	onClose,
}: {
	open: boolean;
	onFactorUpdate: (name: string, value: number) => Promise<void>;
	onClose: () => void;
}) => {
	type State = { name: string; value: number };
	const initialState: State = { name: '', value: 0 };
	type Actions = { payload: number; type: 'SET_VALUE' } | { payload: string; type: 'SET_NAME' } | { type: 'RESET' };
	const reducer: Reducer<State, Actions> = (state, action) => {
		switch (action.type) {
			case 'SET_NAME':
				return { ...state, name: action.payload };
			case 'SET_VALUE':
				return { ...state, value: action.payload };
			case 'RESET':
				return initialState;
			default:
				return state;
		}
	};
	const [state, dispatch] = useReducer(reducer, initialState);
	useEffect(() => {
		if (open) {
			dispatch({ type: 'RESET' });
		}
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose}>
			<div className="p-4">
				<div className="flex flex-row gap-2 mb-4">
					<Input
						placeholder="Factor Name"
						value={state.name}
						onChange={(e) => dispatch({ type: 'SET_NAME', payload: e.target.value })}
					/>
					<Input
						placeholder="Factor Value"
						value={state.value}
						onChange={(e) => {
							if (e.target.value) dispatch({ type: 'SET_VALUE', payload: parseInt(e.target.value ?? 0) });
							else dispatch({ type: 'SET_VALUE', payload: 0 });
						}}
					/>
				</div>
				<div className="text-end">
					<Button
						onClick={() => {
							onFactorUpdate(state.name, state.value);
						}}
					>
						עדכון
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default FactorsModal;
