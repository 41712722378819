import { Card, CardContent, CardHeader, Table, TableCell, TableRow } from '@mui/material';
import React from 'react';

const MoreInfoCard = ({
	info,
	title,
}: {
	title: string;
	info: { title: string; value: string | number | undefined }[];
}) => {
	return (
		<Card style={{ height: '100%' }}>
			<CardHeader title={title} />
			<CardContent>
				<Table dir="rtl" size="small">
					{info.map((row) => (
						<TableRow>
							<TableCell style={{ textAlign: 'right' }} dir="rtl">
								{row.title}
							</TableCell>
							<TableCell>{row.value}</TableCell>
						</TableRow>
					))}
				</Table>
			</CardContent>
		</Card>
	);
};

export default MoreInfoCard;
