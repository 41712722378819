import {
	BooleanInput,
	Edit,
	ImageField,
	ImageInput,
	NumberInput,
	ReferenceArrayInput,
	SelectArrayInput,
	SimpleForm,
	TextInput,
} from 'react-admin';
import { Personalization } from '../Meeting/MeetingCreate';
import { useState } from 'react';

const MediaLinkEdit = () => {
	const [ignoreAge, setIgnoreAge] = useState(false);

	return (
		<Edit>
			<SimpleForm>
				<TextInput source="title" label="שם" dir="rtl" style={{ direction: 'rtl' }} fullWidth />
				<TextInput source="sub_title" label="תת כותרת" dir="rtl" style={{ direction: 'rtl' }} fullWidth />
				<TextInput source="link" fullWidth />
				<ImageField source="thumbnailUrl" />
				<ImageInput source="picture" label="Logo">
					<ImageField source="src" />
				</ImageInput>
				<ReferenceArrayInput reference="vod/categories/new" source="category">
					<SelectArrayInput optionText={'hebrew_name'} />
				</ReferenceArrayInput>
				<NumberInput source="priority" label="קדימות" fullWidth />
				<BooleanInput source="visible" label="זמין" fullWidth defaultChecked={true} />
				<TextInput source="type" />
				<Personalization setIgnoreAge={setIgnoreAge} ignoreAge={ignoreAge} />
			</SimpleForm>
		</Edit>
	);
};

export default MediaLinkEdit;
