import { Box, Grid } from '@mui/material';
import { BooleanInput, Edit, SimpleForm, TextInput } from 'react-admin';

const TagEdit = () => (
	<Edit resource="tag">
		<SimpleForm>
			<Grid spacing={2} container>
				<Grid item sm={4}>
					<TextInput source="name.he" fullWidth />
				</Grid>
				<Grid item sm={4}>
					<TextInput source="name.en" fullWidth />
				</Grid>
				<Box width="100%" />
				<Grid item sm={4}>
					<TextInput source="short_name.he" fullWidth label="שם מקוצר (לדוחות) עברית" />
				</Grid>
				<Grid item sm={4}>
					<TextInput source="short_name.en" fullWidth label="שם מקוצר (לדוחות) אנגלית" />
				</Grid>
				<Box width="100%" />
				<Grid item sm={4}>
					<BooleanInput source="show_in_personalization" fullWidth label="הצגה בהתאמה האישית" />
				</Grid>
				<Grid item sm={4}>
					<BooleanInput source="hidden" fullWidth label="מוסתר" />
				</Grid>
			</Grid>
		</SimpleForm>
	</Edit>
);
export default TagEdit;
