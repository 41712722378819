// in src/MyMenu.js
import { Divider } from '@mui/material';
import { Menu } from 'react-admin';

export const MyMenu = () => (
	<Menu>
		<Menu.DashboardItem />
		<Menu.ResourceItem name="member" />
		<Menu.ResourceItem name="company" />
		<Divider />
		<Menu.ResourceItem name="article" />
		<Menu.ResourceItem name="vod" />
		<Menu.ResourceItem name="media-links" />
		<Menu.ResourceItem name="tag/all" />
		<Menu.ResourceItem name="section" />
		<Menu.ResourceItem name="vod/categories" />
		<Divider />
		<Menu.ResourceItem name="lecture" />
		<Menu.ResourceItem name="lecture/category" />
		<Divider />
		<Menu.ResourceItem name="service" />
		<Menu.ResourceItem name="meeting" />
		<Menu.ResourceItem name="review" />
		<Divider />
		<Menu.ResourceItem name="proactive/item" />
		<Menu.ResourceItem name="prize" />
		<Menu.ResourceItem name="coupon" />
		<Divider />
		<Menu.ResourceItem name="instructor" />
		<Menu.ResourceItem name="instructor-category" />
		<Menu.ResourceItem name="instructor/private_services/sessions" />
		<Menu.ResourceItem name="instructor/sessions/stats/by_host" />
		<Divider />
		<Menu.ResourceItem name="invitation" />
		<Menu.ResourceItem name="setting/trial" />
		<Menu.ResourceItem name="member/email-group" />
		<Menu.ResourceItem name="setting" />
	</Menu>
);
