import { Box, Button, Grid, IconButton } from '@mui/material';
import axios from 'axios';

import { ReactNode, useState } from 'react';
import { SelectInput } from 'react-admin';
import { FaCheck, FaTrashAlt } from 'react-icons/fa';
import { FaX } from 'react-icons/fa6';
import constants from '../../constants';
const AddAllowedEmailsOrPhones = ({
	onPress,
	title,
}: {
	onPress: (v: string) => Promise<void>;
	title?: string | ReactNode;
}) => {
	const [text, setText] = useState('');
	return (
		<div className="w-full flex flex-col items-center gap-2">
			<h4 className="text-base">{title}</h4>
			<textarea value={text} onChange={(e) => setText(e.target.value)} rows={5} className="border w-full p-2" />
			<button
				disabled={!text}
				className="bg-blue-500 text-white py-1 px-2 rounded disabled:opacity-20"
				type="button"
				onClick={async () => {
					try {
						await onPress(text);
						setText('');
					} catch (e) {
						console.error(e);
					} finally {
					}
				}}
			>
				הוספה
			</button>
		</div>
	);
};
const AuthOptions = ({
	record,
	showUpdateVerifierModal,
	showAddDomainModal,
	refetch,
}: {
	record: any;
	showUpdateVerifierModal: (domain_id: string, verifierEmail: string) => void;
	showAddDomainModal: () => void;
	refetch: () => void;
}) => {
	return (
		<Grid container spacing={2}>
			<Grid item sm={4} xs={12}>
				<span>דומייני חברה</span>
				<table className="table-fixed">
					<thead>
						<tr>
							<td width={100} />
							<td width={100} className="text-center" />
							<td width={100} />
							<td width={100} />
							<td width={200} />
						</tr>
					</thead>
					{record?.domains?.map(
						({
							domain,
							verified,
							verifierEmail,
							_id,
						}: {
							_id: string;
							domain: string;
							verifierEmail: string;
							verified: boolean;
						}) => (
							<tr dir="ltr" className="">
								<td className="px-2 py-1 text-left">{domain}</td>
								<td className="px-2 py-1 text-sm text-gray-500 font-extralight">{verifierEmail}</td>
								<td className="px-2 py-1" style={{ color: verified ? 'green' : 'red' }}>
									{verified ? <FaCheck /> : <FaX />}
								</td>

								<td className="px-2 py-1 text-sm text-gray-500 font-extralight">
									<IconButton
										size="small"
										onClick={async () => {
											await axios.delete(`${constants.API_URL}/company/${record._id}/domain`, {
												data: { domain: _id },
											});
										}}
									>
										<FaTrashAlt />
									</IconButton>
								</td>
								{!verifierEmail ? (
									<td>
										<Button onClick={() => showUpdateVerifierModal(_id, domain)}>
											עדכון מייל לאימות
										</Button>
									</td>
								) : null}
							</tr>
						)
					)}
					<tr>
						<td>
							<Button onClick={showAddDomainModal}>הוספה</Button>
						</td>
					</tr>
				</table>
			</Grid>
			<Box width="100%" />
			<Grid item sm={4} xs={12}>
				<SelectInput
					label="אמצעי הזדהות"
					fullWidth
					source="auth_type"
					choices={[
						{ id: 'phone', name: 'טלפון' },
						{ id: 'email', name: 'מייל' },
					]}
					helperText="יש לשמור לפני עדכון פרטים נוספים"
				/>
				<AddAllowedEmailsOrPhones
					title={
						<span>
							{`הוספת ${record.auth_type === 'phone' ? 'מספרי טלפון' : 'מיילים'} מאושרים`}
							<span className="text-xs">{` (${
								(record.auth_type === 'phone'
									? record.allowed_phones?.length
									: record.allowed_emails?.length) ?? 0
							} קיימים)`}</span>
						</span>
					}
					onPress={async (values) => {
						const endpoint = record.auth_type === 'phone' ? 'add_allowed_phones' : 'add_allowed_emails';
						const res = await axios.post(`${constants.API_URL}/company/${record._id}/${endpoint}`, {
							values,
						});

						alert(
							`נוספו ${res.data.addedCount}${
								res.data.errors?.length ? `\n\nנכשלו:\n${res.data.errors.join('\n')}` : ''
							}`
						);
						refetch();
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default AuthOptions;
