import { BooleanField, Datagrid, DateField, FunctionField, List, NumberField, TextField } from 'react-admin';

const CouponList = () => {
	return (
		<List>
			<Datagrid rowClick="show">
				<TextField source="code" label="קוד קופון" />
				<TextField source="product" />
				<FunctionField
					label="שווי קופון"
					render={(record: any) => `${record.discountValue}${record.discountType === 'fixed' ? '₪' : '%'}`}
				/>
				<NumberField source="usedCount" label="שימושים" />
				<BooleanField source="active" />
				<DateField source="expirationDate" />
			</Datagrid>
		</List>
	);
};

export default CouponList;
