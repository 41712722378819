import { Create, NumberInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import VideoUploadInput from '../Inputs/VideoUploadInput';

const ProactiveItemCreate = () => {
	return (
		<Create>
			<SimpleForm dir="rtl">
				<div className="w-full">
					<div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-8">
						<h5 className="col-span-2 font-medium text-start">כותרת</h5>
						<TextInput
							source="title.he"
							label="עברית"
							fullWidth
							required
							margin="none"
							helperText={false}
						/>
						<TextInput
							source="title.en"
							label="אנגלית"
							fullWidth
							required
							margin="none"
							helperText={false}
						/>
					</div>

					<div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-8">
						<h5 className="col-span-2 font-medium text-start">כותרת משנה</h5>

						<TextInput source="sub_title.he" label="עברית" fullWidth helperText={false} />
						<TextInput source="sub_title.en" label="אנגלית" fullWidth helperText={false} />
					</div>
					<div className="grid-cols-1 md:grid-cols-2 md:grid gap-4 mb-8">
						<h5 className="col-span-2 font-medium text-start">טקסט קידום</h5>
						<TextInput source="promotion_text.he" label="עברית" fullWidth helperText={false} />
						<TextInput source="promotion_text.en" label="אנגלית" fullWidth helperText={false} />
					</div>
					<div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-8">
						<h5 className="col-span-2 font-medium text-start">תיאור</h5>
						<TextInput multiline source="description.he" label="עברית" fullWidth helperText={false} />
						<TextInput multiline source="description.en" label="אנגלית" fullWidth helperText={false} />
					</div>

					<div className="grid-cols-1 md:grid-cols-6 grid gap-4 mb-8">
						<h5 className="col-span-6 font-medium text-start">נתונים נוספים</h5>
						<TextInput className="col-span-2" source="url" label="URL" />
						<TextInput className="col-span-2" source="thumbnail" label="thumbnail" />
						<TextInput className="col-span-2" source="image" label="image" />
						<NumberInput source="duration" label="משך בשניות" fullWidth required />
						<NumberInput source="points" label="נקודות" fullWidth required />
						<TextInput
							source="slug"
							label="SLUG (מזהה ייחודי)"
							fullWidth
							required
							inputProps={{ style: { textTransform: 'uppercase' } }}
							helperText="UPPERCASE only"
						/>
						<SelectInput
							className="col-span-2"
							margin="none"
							source="type"
							optionText={'label'}
							choices={[
								{ id: 'spotify', label: 'Spotify' },
								{ id: 'ted', label: 'TED' },
								{ id: 'video', label: 'video' },
							]}
							label="סוג תוכן"
							fullWidth
							required
						/>
					</div>
				</div>
				<VideoUploadInput source="video" disabled />
			</SimpleForm>
		</Create>
	);
};

export default ProactiveItemCreate;
