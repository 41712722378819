import { BooleanField, ChipField, Datagrid, List, NumberField, ReferenceField, TextField } from 'react-admin';

const MediaCategory = () => (
	<List>
		<Datagrid rowClick="edit">
			<TextField source="hebrew_name" />
			<TextField source="english_name" />
			<BooleanField source="visible" />
			<TextField source="type" />
			<NumberField source="priority" label="עדיפות" />
			<NumberField source="age.from" />
			<ReferenceField source="parent_category" reference="vod/categories">
				<ChipField source="hebrew_name" />
			</ReferenceField>
		</Datagrid>
	</List>
);

export default MediaCategory;
