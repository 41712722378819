import { BooleanField, Datagrid, List, TextField, useResourceContext } from 'react-admin';

const TagList = () => {
	const context = useResourceContext({ resource: 'tag' });
	return (
		<List resource={'tag/all'} title={'תגים'} hasCreate={true}>
			<Datagrid rowClick="edit" resource={context} style={{ textAlign: 'right' }}>
				<TextField source="name.he" label="עברית" textAlign="right" dir="rtl" width={100} />
				<TextField source="name.en" label="אנגלית" textAlign="right" dir="rtl" width={100} />
				<BooleanField source="show_in_personalization" />
			</Datagrid>
		</List>
	);
};

export default TagList;
