import { Card, CardContent, CardHeader, LinearProgress, Typography } from '@mui/material';
import React from 'react';

const ExpertsCard = ({
	loading,
	total,
	registeredExperts,
	synchedExperts,
	safeSpaceExperts,
}: {
	loading?: boolean;
	total?: number;
	registeredExperts?: number;
	synchedExperts?: number;
	safeSpaceExperts?: number;
}) => {
	return (
		<Card style={{ height: '100%' }}>
			<CardHeader title="מומחים בגרופהאג" />
			<CardContent>
				{loading ? (
					<LinearProgress />
				) : (
					<span>
						<Typography variant="h3">{total}</Typography>
						<Typography variant="body1">{`${registeredExperts} נרשמו למערכת`}</Typography>
						<Typography variant="body1">{`${synchedExperts} השלימו סינכרון יומן`}</Typography>
						<Typography variant="body1">{`${safeSpaceExperts} מטפלים במרחב הבטוח`}</Typography>
					</span>
				)}
			</CardContent>
		</Card>
	);
};

export default ExpertsCard;
