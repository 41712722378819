import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import {
	BooleanInput,
	Create,
	FormDataConsumer,
	ImageField,
	ImageInput,
	ReferenceArrayInput,
	SelectArrayInput,
	SelectInput,
	SimpleForm,
	TextInput,
} from 'react-admin';

export const ExpertCreate = () => (
	<Create resource="instructor">
		<SimpleForm>
			<Grid container spacing={2}>
				<Grid item sm={4}>
					<TextInput source="name" fullWidth required />
				</Grid>
				<Grid item sm={4}>
					<TextInput source="english_name" fullWidth required />
				</Grid>
				<Box width="100%" />
				<Grid item sm={2}>
					<ReferenceArrayInput reference="instructor-category/all" source="category" perPage={999}>
						<SelectArrayInput
							optionText={(record) =>
								`${record.__t === 'helping_hand' ? 'HH' : ''} ${record.hebrew_name}`
							}
							optionValue="id"
							style={{ marginTop: -1 }}
						/>
					</ReferenceArrayInput>
				</Grid>
				<Box width="100%" />
				<Grid item sm={8}>
					<TextInput source="title" fullWidth required />
				</Grid>
				<Grid item sm={8}>
					<TextInput source="english_title" fullWidth required />
				</Grid>
				<Box width="100%" />
				<Grid item sm={3}>
					<TextInput source="short_title.he" fullWidth inputProps={{}} />
				</Grid>
				<Grid item sm={3}>
					<TextInput source="short_title.en" fullWidth inputProps={{}} />
				</Grid>
				<Box width="100%" />
				<Grid item sm={8}>
					<TextInput source="description_lng.he" multiline fullWidth label={'תיאור בעברית'} />
				</Grid>
				<Grid item sm={8}>
					<TextInput source="description_lng.en" multiline fullWidth label={'תיאור באנגלית'} dir="ltr" />
				</Grid>
				<Box width="100%" />
				<Grid item sm={3}>
					<TextInput source="location" multiline fullWidth label={'מיקום קליניקה'} dir="rtl" />
				</Grid>
				<Grid item sm={3}>
					<TextInput
						source="languages"
						multiline
						fullWidth
						label={'שפות (מופרד ב,)'}
						dir="rtl"
						format={(value: string[]) => {
							return (value || [])?.join(',');
						}}
						parse={(value: string) => value?.split(',')}
					/>
				</Grid>
				<Grid item sm={3}>
					<BooleanInput source="is_online" fullWidth label={'מקבל אונליין'} dir="ltr" defaultChecked />
				</Grid>
				<Grid item sm={3}>
					<BooleanInput source="is_psychologist" fullWidth label="פסיכולוג/ית" dir="ltr" />
				</Grid>
				<Grid item sm={3}>
					<BooleanInput source="licensed_dealer" fullWidth label="עוסק מורשה" dir="ltr" />
				</Grid>
				<Box width="100%" />
				<Grid item sm={8}>
					<TextInput source="image" fullWidth />
				</Grid>
				<Grid item sm={8}>
					<ImageInput source="image">
						<ImageField source="src" />
					</ImageInput>
				</Grid>
				<Box width={'100%'} />
				<Grid item sm={3}>
					<SelectInput
						fullWidth
						source="anchorImage"
						style={{ marginTop: 8 }}
						choices={[
							{ id: 'top', name: 'top' },
							{ id: 'center', name: 'center' },
							{ id: 'bottom', name: 'bottom' },
						]}
					/>
				</Grid>
				<Box width="100%" />
				<Grid item sm={3}>
					<TextInput source="email" fullWidth />
				</Grid>
				<Grid item sm={3}>
					<TextInput source="phone" fullWidth />
				</Grid>
				<Box width="100%" />
				<Grid item sm={3}>
					<TextInput source="button_text.he" label="טקסט כפתור (עב)" fullWidth />
				</Grid>
				<Grid item sm={3}>
					<TextInput source="button_text.en" label="טקסט כפתור (אנ)" fullWidth />
				</Grid>
				<Box width="100%" />
				<Grid item sm={6}>
					<TextInput source="zoom" fullWidth />
				</Grid>
				<Grid item sm={2}>
					<TextInput source="zoom_password" fullWidth />
				</Grid>
				<Grid item sm={6}>
					<TextInput source="url" fullWidth />
				</Grid>
				<Box width={'100%'} />
				<Grid item sm={6}>
					<TextInput source="helping_hand_url" fullWidth />
				</Grid>
				<Box width={'100%'} />
				<Grid item xs={2}>
					<BooleanInput source="safe_space" label="המרחב הבטוח" />
				</Grid>
				<FormDataConsumer>
					{({ formData }) =>
						formData.safe_space && (
							<>
								<Grid item xs={2}>
									<BooleanInput
										source="safe_space_filter_trauma"
										label="המרחב הבטוח - חרדה וטראומה"
									/>
								</Grid>
								<Grid item xs={2}>
									<BooleanInput
										source="safe_space_filter_children_therapy_3_10"
										label="המרחב הבטוח - 3-10"
									/>
								</Grid>
								<Grid item xs={2}>
									<BooleanInput
										source="safe_space_filter_children_therapy_10_18"
										label="המרחב הבטוח - 10-18"
									/>
								</Grid>
							</>
						)
					}
				</FormDataConsumer>
				<Grid item xs={2}>
					<BooleanInput source="showInSubscription" label="מופיע/ה במנוי" />
				</Grid>
				<Grid item xs={2}>
					<BooleanInput source="active" label="פעילה" defaultValue={true} />
				</Grid>
			</Grid>
		</SimpleForm>
	</Create>
);

export default ExpertCreate;
