import { BooleanInput, Button, useNotify } from 'react-admin';
import constants from '../../../constants';
import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import axios, { AxiosError } from 'axios';
import { getLocalizedErrorMessage } from '../../../utils/functions';
import { CgMathPlus, CgTrash } from 'react-icons/cg';
import { Input } from '@mui/material';

type HRInfo = { first_name: string; last_name: string; email: string; _id: string };
const HREdit = ({ company_id }: { company_id: string }) => {
	const HR_URL = `${constants.API_URL}/company/${company_id}/hr`;
	const { data: hrs, fetchAgain } = useFetch<HRInfo[]>(HR_URL);
	const [hrEmail, setHrEmail] = useState('');
	const snackbar = useNotify();
	const [isLoading, setisLoading] = useState(false);
	const handleRemoveHR = async ({ user_id }: { user_id: string }) => {
		try {
			setisLoading(true);
			await axios.delete(HR_URL, { data: { member_id: user_id } });
			await fetchAgain();
			snackbar('HR הוסר/ה בהצלחה', { type: 'success' });
		} catch (error) {
			snackbar(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
		} finally {
			setisLoading(false);
		}
	};
	const handleAddHR = async ({ email }: { email: string }) => {
		try {
			setisLoading(true);
			await axios.post(HR_URL, { email });
			await fetchAgain();
			snackbar(`${email} נוסף/ה בהצלחה`, { type: 'success' });
		} catch (error) {
			snackbar(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
		} finally {
			setisLoading(false);
		}
	};
	return (
		<div>
			<BooleanInput source="showHrSection" label="מתחם HR זמין" />
			<h2 className="font-bold">מנהלי/ות HR בחברה</h2>
			<table className="border-separate border-spacing-x-3 border-spacing-y-2">
				<thead>
					<tr>
						<th>שם</th>
						<th>מייל</th>
						<th>הסרה</th>
					</tr>
				</thead>
				{hrs?.map((hr) => (
					<tr>
						<td>{`${hr.first_name} ${hr.last_name}`}</td>
						<td>{hr.email}</td>
						<td>
							<Button onClick={() => handleRemoveHR({ user_id: hr._id })} disabled={isLoading}>
								<CgTrash color="red" />
							</Button>
						</td>
					</tr>
				))}
				<tr>
					<td colSpan={2}>
						<Input
							disabled={isLoading}
							value={hrEmail}
							onChange={(e) => setHrEmail(e.target.value)}
							fullWidth
							placeholder="הוספת מייל HR"
						/>
					</td>
					<td>
						<Button onClick={() => handleAddHR({ email: hrEmail })} disabled={isLoading}>
							<CgMathPlus />
						</Button>
					</td>
				</tr>
			</table>
		</div>
	);
};

export default HREdit;
