import { Grid } from '@mui/material';
import {
	BooleanInput,
	Create,
	NumberInput,
	RedirectionSideEffect,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
} from 'react-admin';

const ReviewCreate = () => {
	const redirect: RedirectionSideEffect = () => `review`;

	return (
		<Create resource="review/new" redirect={redirect}>
			<SimpleForm>
				<Grid container spacing={2}>
					<Grid item md={4} xs={12}>
						<TextInput source="text" fullWidth label="טקסט משוב" />
					</Grid>

					<Grid item md={3} xs={12}>
						<TextInput source="meeting_title" fullWidth label="כותרת מפגש" />
					</Grid>
					<Grid item md={3} xs={12}>
						<ReferenceInput reference="company" source="company" label="חברה" perPage={999}>
							<SelectInput optionText={'english_name'} fullWidth style={{ marginTop: 0 }} />
						</ReferenceInput>
					</Grid>
					<Grid item md={1} xs={6}>
						<NumberInput source="rating" fullWidth max={5} min={1} />
					</Grid>
					<Grid item md={2} xs={6}>
						<BooleanInput source="showToHR" fullWidth label="הצג ל-HR" />
					</Grid>
				</Grid>
			</SimpleForm>
		</Create>
	);
};

export default ReviewCreate;
