import { Box, Button, Dialog, Input, Typography } from '@mui/material';
import { useState } from 'react';

const DeleteCompanyDialog = ({
	companyName,
	handleDeleteConfirm,
	handleCloseDialog,
	isVisible,
}: {
	companyName: string;
	handleDeleteConfirm: (confirm: string) => void;
	isVisible: boolean;
	handleCloseDialog: () => void;
}) => {
	const [deleteVerificationText, setDeleteVerificationText] = useState('');
	return (
		<Dialog open={isVisible} onClose={handleCloseDialog}>
			<Box p={2} display="flex" flexDirection="column" gap={2}>
				<Typography fontStyle={'italic'} display={'inline'}>
					{'יש לכתוב '}
					<Typography fontWeight={'bold'} display={'inline'}>
						{companyName}
					</Typography>
					{' כדי להמשיך'}
				</Typography>
				<Input onChange={(e) => setDeleteVerificationText(e.target.value)} value={deleteVerificationText} />
				<Button
					disabled={companyName !== deleteVerificationText}
					color="error"
					onClick={() => handleDeleteConfirm(deleteVerificationText)}
				>
					אישור מחיקה
				</Button>
			</Box>
		</Dialog>
	);
};

export default DeleteCompanyDialog;
