import { Button, Checkbox, FormControlLabel, Tooltip, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import {
	BooleanField,
	BooleanInput,
	BulkActionProps,
	CreateButton,
	DatagridConfigurable,
	ExportButton,
	FilterButton,
	FunctionField,
	List,
	NumberField,
	SelectColumnsButton,
	SimpleList,
	TextField,
	TextInput,
	useNotify,
	useResourceContext,
} from 'react-admin';
import constants from '../../constants';
import { downloadEncodedCSV } from '../../utils/functions';
import MyPagination from '../MyPagination';

const CopyEmailsButton = (props: BulkActionProps) => {
	const notify = useNotify();
	const handleClick = async () => {
		try {
			const response = await axios.get(`${constants.API_URL}/admin/company-users-emails`, {
				params: { filter: { ids: props.selectedIds } },
			});
			navigator.clipboard.writeText(response.data);
			notify('הועתק', { type: 'success' });
		} catch (error) {
			notify('רגע... נסו שוב', { type: 'error' });
		}
	};
	return <Button onClick={handleClick}>COPY EMAIL TO CLIPBOARD</Button>;
};
const CompanyList = () => {
	const [showBlackBackgrounds, setShowBlackBackgrounds] = useState(false);
	const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
	const context = useResourceContext({ resource: 'company' });
	const renderCompanyLogo = (record: any) => (
		<span
			style={{
				flex: 1,
				display: 'flex',
				height: 65,
				width: 65,
				alignItems: 'center',
				backgroundColor: showBlackBackgrounds ? 'black' : undefined,
				justifyContent: 'center',
			}}
		>
			<span
				style={{
					display: 'flex',
					textAlign: 'center',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: 60,
					height: 60,
					width: 60,
					backgroundColor: record.logo_background || 'white',
				}}
			>
				<img
					src={record.logo}
					style={{ display: 'flex', height: 50, width: 50, objectFit: 'contain' }}
					alt={`${record.english_name}_logo`}
				/>
			</span>
		</span>
	);
	const exporter = (company: any) => {
		const companyForExport = company.map((company: any) => ({
			name: company.english_name,
			subscription_start: company.subscription_start,
			active: company.active,
		}));

		downloadEncodedCSV(companyForExport, ['name', 'subscription_start', 'active'], 'company');
	};
	return (
		<List
			filters={[
				<TextInput source="english_name" label="שם" />,
				<BooleanInput source="active" label="חברות פעילות" dir="rtl" />,
			]}
			filterDefaultValues={{ active: true }}
			perPage={100}
			sort={{ field: 'english_name', order: 'ASC' }}
			pagination={<MyPagination />}
			actions={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						justifyContent: 'flex-end',
						paddingInlineEnd: 16,
					}}
				>
					<ExportButton exporter={exporter} />
					<FilterButton />
					<CreateButton />
					<SelectColumnsButton />
					<FormControlLabel
						label={<Typography variant="body1">הדגמת רקע שחור ללוגו (כמו באפליקציה)</Typography>}
						control={
							<Checkbox
								onChange={(e) => setShowBlackBackgrounds(e.target.checked)}
								checked={showBlackBackgrounds}
							/>
						}
					/>
				</div>
			}
		>
			{isSmall ? (
				<SimpleList
					resource={context}
					sx={{ textAlign: 'right' }}
					style={{ textAlign: 'right' }}
					primaryText={(record) => (
						<Typography textAlign="right" fontWeight={'600'}>
							{`${record.english_name}`}
						</Typography>
					)}
					secondaryText={(record) => (
						<img
							width="70px"
							height="30px"
							style={{ objectFit: 'contain' }}
							src={`${record.logo}`}
							alt="logo"
						/>
					)}
				/>
			) : (
				<DatagridConfigurable
					rowClick="edit"
					size="small"
					dir="rtl"
					bulkActionButtons={<CopyEmailsButton />}
					sx={{
						'& .column-undefined': { width: 12 },
						'& .column-allow_proactive_app': { maxWidth: 20 },
						'& .column-boostsCount': { maxWidth: 10 },
						'& .column-active': { maxWidth: 10 },
					}}
				>
					<FunctionField textAlign="center" render={renderCompanyLogo} margin={0} label="לוגו" />
					<FunctionField
						textAlign="right"
						fontWeight={'bold'}
						sortBy="english_name"
						render={(record: any) => (
							<div>
								{record.english_name}
								<span className="ms-2">
									{record.domains?.length && record.allDomainsVerified ? (
										<span className="text-green-500">🟢</span>
									) : (
										<Tooltip
											title={
												!record.domains?.length
													? 'לא קיימים דומיינים'
													: !record.allDomainsVerified
													? 'לא כל הדומיינים מאומתים'
													: undefined
											}
										>
											<span className="text-red-500">🔴</span>
										</Tooltip>
									)}
								</span>
							</div>
						)}
						label="שם חברה"
					/>
					<FunctionField
						label="רשומים"
						render={(record: any) => (
							<span className="block min-w-20">
								<Typography fontWeight={'600'}>
									{`אמת: ${record.employees?.real ?? 0}`}
									{record.employees?.factor ? (
										<Typography display={'block'} variant="caption">{`פקטור: ${
											record.employees?.factor ?? 0
										}`}</Typography>
									) : null}
								</Typography>
							</span>
						)}
					/>
					<BooleanField textAlign="right" label="תכנית פרואקטיבית" source="allow_proactive_app" looseValue />
					<NumberField
						source="boostsCount"
						label={
							<div className="">
								<span>בוסטים</span>
								<br className="leading-none" />
								<p className="text-xs leading-tight text-gray-500">ב-3 חודשים האחרונים</p>
							</div>
						}
					/>
					<NumberField source="nps" label="NPS" />
					<FunctionField
						label="HR"
						render={(record: any) => (
							<Tooltip
								hidden={!record.hrs.length}
								title={
									<ol className="list-decimal px-3 py-1">
										{record.hrs?.map((x: any) => (
											<li>{`${x.first_name} ${x.last_name}`}</li>
										))}
									</ol>
								}
							>
								<div className="text-center w-20">
									<span className="block">{record.hrs?.length}</span>
									<span className="block text-xs">{record.showHrSection ? 'מתחם  HR זמין' : ''}</span>
								</div>
							</Tooltip>
						)}
					/>
					<BooleanField textAlign="right" label="פעיל?" source="active" />;
				</DatagridConfigurable>
			)}
		</List>
	);
};
export default CompanyList;
