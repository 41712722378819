import { Box, Grid } from '@mui/material';
import {
	ArrayInput,
	BooleanInput,
	Edit,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SimpleFormIterator,
	TabbedForm,
	TextInput,
} from 'react-admin';

const MemberEdit = () => {
	return (
		<Edit mutationMode="pessimistic">
			<TabbedForm>
				<TabbedForm.Tab label="נתונים">
					<Grid container spacing={2}>
						<Grid item sm={3}>
							<TextInput source="first_name" fullWidth />
						</Grid>
						<Grid item sm={3}>
							<TextInput source="last_name" fullWidth />
						</Grid>
						<Box width="100%" />
						<Grid item sm={3}>
							<TextInput source="email" fullWidth />
						</Grid>
						<Grid item sm={3}>
							<TextInput source="phone" fullWidth />
						</Grid>
						<Grid item sm={4}>
							<ReferenceInput source="company" perPage={999} reference="company" link={true}>
								<SelectInput optionText="english_name" fullWidth />
							</ReferenceInput>
						</Grid>
						<Grid item sm={4}>
							<BooleanInput source="hr" fullWidth label='מנהל/ת מש"א' />
						</Grid>
						<Box width="100%" />
						<Grid item sm={3}>
							<NumberInput source="safe_space_price" fullWidth />
						</Grid>
						<Box width="100%" />
						<Grid item sm={3}>
							<ArrayInput source="safe_space_prices" fullWidth>
								<SimpleFormIterator
									disableAdd={false}
									field={(field: any) => ({ ...field, isDirty: true })}
								>
									<NumberInput source="price" alwaysOn={true} />
								</SimpleFormIterator>
							</ArrayInput>
						</Grid>
					</Grid>
				</TabbedForm.Tab>
			</TabbedForm>
		</Edit>
	);
};
export default MemberEdit;
