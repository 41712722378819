import {
	BooleanField,
	ChipField,
	Datagrid,
	List,
	NumberField,
	ReferenceArrayField,
	SingleFieldList,
	TextField,
} from 'react-admin';

const EmailGroupList = () => {
	return (
		<List>
			<Datagrid rowClick="edit">
				<TextField source="groupId" />
				<TextField source="name" />
				<BooleanField source="allCompanies" />
				<ReferenceArrayField reference="company" source="excludeCompanies">
					<SingleFieldList>
						<ChipField source="english_name" size="small" />
					</SingleFieldList>
				</ReferenceArrayField>
				<ReferenceArrayField reference="company" source="includeCompanies">
					<SingleFieldList>
						<ChipField source="english_name" size="small" />
					</SingleFieldList>
				</ReferenceArrayField>
				<NumberField source="usersCount" />
			</Datagrid>
		</List>
	);
};

export default EmailGroupList;
