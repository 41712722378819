import { BooleanField, Datagrid, List, TextField, useResourceContext } from 'react-admin';

const SettingList = () => {
	const context = useResourceContext({ resource: 'setting' });
	return (
		<List resource="setting/all">
			<Datagrid rowClick="edit" resource={context}>
				<TextField source="key" />
				<BooleanField source="value" />
			</Datagrid>
		</List>
	);
};
export default SettingList;
