import {} from '@mui/system';
import { Datagrid, List, TextField } from 'react-admin';

const TrialUsersList = () => {
	// const [newTrial, setNewTrial] = useState({ email: '', type: '' });

	return (
		<List perPage={10000} pagination={false}>
			<Datagrid>
				<TextField source="email" />
				<TextField source="type" />
			</Datagrid>
		</List>
	);
};

export default TrialUsersList;
