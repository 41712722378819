import { Box, Container, Grid, TextField as MuiTextField } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	ArrayInput,
	FunctionField,
	NumberInput,
	ReferenceField,
	SimpleForm,
	SimpleFormIterator,
	TextField,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
} from 'react-admin';
import { useParams, useSearchParams } from 'react-router-dom';
import constants from '../../../constants';

const MonthlySessionEdit = () => {
	const { id } = useParams();
	let [searchParams] = useSearchParams();
	const year = searchParams.get('year');
	const month = searchParams.get('month');

	const [date, setDate] = useState(
		new Date(year ? parseInt(year) : moment().year(), month ? parseInt(month) : moment().year(), 1),
	);
	const [data, setData] = useState<Object | undefined>();

	const refresh = useRefresh();
	const notify = useNotify();
	useEffect(() => {
		const fecthData = async () => {
			try {
				const response = await axios.get(`${constants.API_URL}/instructor/sessions/stats/by_host/${id}`, {
					params: { filter: { month, year } },
				});
				setData(response.data?.data);
			} catch (error) {
				setData(undefined);
				notify('אין נתונים לחודש זה. יש לחזור לעמוד הקודם ולבצע "עדכון נתונים"', { type: 'error' });
				refresh();
			}
		};
		fecthData();
	}, [id, month, year, notify, refresh]);
	const redirect = useRedirect();

	return (
		<Container style={{ marginBlock: 12 }}>
			<MuiTextField
				value={moment(date).format('YYYY-MM')}
				type="month"
				onChange={(e) => {
					setDate(new Date(e.target.value));
				}}
			/>
			{data && (
				<SimpleForm
					record={data}
					onSubmit={async (data) => {
						const { groupSessions, notes, fixedPayment } = data;
						try {
							await axios.put(`${constants.API_URL}/instructor/monthly_sessions/${id}`, {
								month,
								year,
								groupSessions,
								fixedPayment,
								notes,
							});
							notify('הנתונים עודכנו', { type: 'success' });
							redirect('list', 'instructor/sessions/stats/by_host');
						} catch (error) {
							notify('משהו השתבש!', { type: 'error' });
						}
					}}
				>
					<Grid container spacing={2}>
						<ReferenceField source="expert" reference="instructor/all">
							<TextField source="name" fontSize={30} />
						</ReferenceField>
						<Box width="100%" />
						<h4>הרצאות וסדנאות</h4>
						<Grid item xs={12}>
							<ArrayInput source="groupSessions" label="" fullWidth>
								<SimpleFormIterator
									inline
									sx={{ alignItems: 'center' }}
									disableAdd
									disableClear
									disableRemove
									disableReordering
								>
									<ReferenceField link={false} reference="meeting" source="session_id">
										<FunctionField
											style={{ height: '100%', display: 'flex', alignItems: 'center' }}
											render={(record: any) => (
												<span style={{ direction: 'rtl' }}>
													<span
														style={{
															marginInlineEnd: 16,
															fontWeight: 'bold',
															display: 'inline-block',
															width: 200,
															textAlign: 'start',
														}}
													>
														{record.title}
													</span>
													<span
														style={{
															marginInlineEnd: 16,
															display: 'inline-block',
															width: 80,
															textAlign: 'center',
														}}
													>{`${moment(record.time).format('DD/MM')}`}</span>
													<span
														style={{
															display: 'inline-block',
															width: 100,
															textAlign: 'center',
														}}
													>{` ${record.registered} רשומים`}</span>
												</span>
											)}
										/>
									</ReferenceField>
									<NumberInput source="fixedPayment" helperText={false} label="הזנת סכום ידני" />
								</SimpleFormIterator>
							</ArrayInput>
						</Grid>
						<h4>הערות</h4>
						<Box width="100%" />

						<Grid item xs={4}>
							<NumberInput fullWidth source="fixedPayment" label="תוספת לתשלום" />
						</Grid>
						<Grid item xs={8}>
							<TextInput fullWidth multiline source="notes" label="הערות" />
						</Grid>
					</Grid>
				</SimpleForm>
			)}
		</Container>
	);
};

export default MonthlySessionEdit;
