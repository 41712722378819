import { AxiosError } from 'axios';
import Papa from 'papaparse';
import { downloadCSV } from 'react-admin';

export const getLocalizedErrorMessage = (error: AxiosError, language: string = 'he') => {
	return typeof (error.response?.data as any)?.message === 'string'
		? (error.response?.data as any)?.message
		: language === 'he'
			? (error.response?.data as any).message.he
			: (error.response?.data as any).message.en;
};

export function nFormatter(num: number, digits: number) {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return num >= item.value;
		});
	return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

export const formatPrice = (price: number) => price.toFixed(2).replace(/[.,]00$/, '');

export function downloadEncodedCSV(input: any, fields: string[] | undefined, fileName: string) {
	const csv = Papa.unparse(input, { columns: fields });
	const BOM = '\uFEFF';
	downloadCSV(`${BOM} ${csv}`, fileName);
}
