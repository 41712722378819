import { Chip } from '@mui/material';
import { Box } from '@mui/system';
import {
	BooleanField,
	ChipField,
	CreateButton,
	Datagrid,
	ExportButton,
	FunctionField,
	Link,
	List,
	ReferenceArrayField,
	SingleFieldList,
	TextField,
	useResourceContext,
} from 'react-admin';

const CategoryList = () => {
	const context = useResourceContext({ resource: 'instructor-category' });
	return (
		<List
			resource="instructor-category/all"
			title={'קטגוריות'}
			actions={
				<Box>
					<CreateButton resource="instructor-category" />
					<ExportButton />
				</Box>
			}
		>
			<Datagrid rowClick="edit" resource={context}>
				<FunctionField
					label="שם"
					render={(record: any) => (
						<div>
							<div className="text-sm font-medium">
								{record?.hebrew_name}
								{record.__t === 'helping_hand' ? (
									<span className="ps-1 text-xs text-blue-600 font-medium">(עזרה ראשונה רגשית)</span>
								) : (
									''
								)}
							</div>
							<div className="text-xs">{record.english_name}</div>
						</div>
					)}
				/>
				<FunctionField
					label="קטגוריית אם"
					render={(record: any) =>
						record?.parent ? (
							<Link
								style={{}}
								onClick={(e) => e.stopPropagation()}
								to={`/instructor-category/${record.parent?._id}`}
							>
								<Chip size="small" style={{ cursor: 'alias' }} label={record?.parent?.hebrew_name} />
							</Link>
						) : null
					}
					emptyText="-"
					textAlign="center"
					align="center"
					style={{ textAlign: 'center' }}
				/>
				<ReferenceArrayField source="subCategories" label="תתי קטגוריות" reference="instructor-category/all">
					<SingleFieldList style={{ maxWidth: 400 }}>
						<ChipField style={{ cursor: 'alias' }} source="hebrew_name" size="small" />
					</SingleFieldList>
				</ReferenceArrayField>

				<BooleanField source="active" />
				<TextField source="order" label="סדר" textAlign="right" dir="rtl" width={40} />
			</Datagrid>
		</List>
	);
};

export default CategoryList;
