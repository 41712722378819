import { Box, Grid, Typography } from '@mui/material';
import {
	ArrayInput,
	BooleanInput,
	Create,
	DateInput,
	ImageField,
	ImageInput,
	NumberInput,
	SaveButton,
	SelectArrayInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	Toolbar,
} from 'react-admin';

const CompanyToolBar = (props: any) => (
	<Toolbar>
		<SaveButton />
	</Toolbar>
);
const CompanyCreate = () => {
	return (
		<Create>
			<SimpleForm toolbar={<CompanyToolBar />}>
				<Grid container spacing={2} alignItems={'cemter'}>
					<Grid item sm={3}>
						<TextInput label="שם חברה" source="english_name" fullWidth />
					</Grid>

					<Grid item sm={3}>
						<TextInput
							source="logo_background"
							label="רקע לוגו"
							type="color"
							style={{ width: 150 }}
							fullWidth
						/>
					</Grid>
					<Box width="100%" />
					<Grid item sm={3}>
						<ArrayInput source="company_email_domains" label="דומיין חברה">
							<SimpleFormIterator inline disableClear fullWidth>
								<TextInput source="" helperText={false} fullWidth />
							</SimpleFormIterator>
						</ArrayInput>
					</Grid>
					<Grid item sm={3}>
						<ArrayInput source="allowed_emails" label="אימיילים מאושרים">
							<SimpleFormIterator inline disableClear fullWidth>
								<TextInput source="" helperText={false} fullWidth />
							</SimpleFormIterator>
						</ArrayInput>
					</Grid>
					<Box width="100%" />
					<ImageInput label="החלפת לוגו" source="picture" accept={[`image/*`]}>
						<ImageField source="src" />
					</ImageInput>
					<Grid item xs={12}>
						<Typography variant="h6" textAlign={'start'}>
							מתחמים
						</Typography>
					</Grid>
					<Grid item sm={2}>
						<BooleanInput source="helping_hand" label="עזרה ראשונה (Helping hand)" />
					</Grid>
					<Grid item sm={2}>
						<BooleanInput label="הזמנת בן משפחה" fullWidth source="invite_family_members" />
					</Grid>
					<Grid item sm={2}>
						<BooleanInput label="תכנית פרואקטיבית?" fullWidth source="allow_proactive_app" />
					</Grid>
					<Grid item sm={2}>
						<BooleanInput label="שיחות הכוונה?" fullWidth source="one_to_one_available" />
					</Grid>
					<Grid item sm={3}>
						<SelectArrayInput
							style={{ marginTop: -10 }}
							fullWidth
							choices={[
								{ name: 'Dating', id: 'DATING' },
								{ name: 'Care Givers', id: 'CARE_GIVERS' },
								{ name: 'Cool Stuff', id: 'COOL_STUFF' },
								{ name: 'קשב וריכוז', id: 'ADHD' },
							]}
							source="imagebuttons_names"
						/>
					</Grid>
					<Box width="100%" />

					<Grid item xs={12}>
						<Typography variant="h6" textAlign={'start'}>
							מרחב בטוח
						</Typography>
					</Grid>
					<Grid item sm={2}>
						<BooleanInput source="safe_space" label="המרחב הבטוח" />
					</Grid>
					{/* <Grid item sm={3}>
						<BooleanInput source='safe_space_emergency' label='המרחב הבטוח - ללא סבסוד (יש לסמן גם את "מרחב בטוח")' />
					</Grid> */}
					<Grid item sm={3}>
						<BooleanInput source="safe_space_for_family_members" label="הצגת מרחב בטוח לבני משפחה" />
					</Grid>
					<Box width="100%" />
					<Grid item sm={4}>
						<TextInput fullWidth source="safe_space_text_lng.he" label="טקסט מרחב בטוח" multiline />
					</Grid>
					<Grid item sm={4}>
						<TextInput fullWidth source="safe_space_text_lng.en" label="טקסט מרחב בטוח (אנ')" multiline />
					</Grid>

					<Box width="100%" />
					<Grid item xs={12}>
						<Typography variant="h6" textAlign={'start'}>
							טקסט פתיחה
						</Typography>
					</Grid>
					<Grid item sm={4}>
						<TextInput fullWidth source="hello_text" label="כותרת לטקסט פתיחה" />
					</Grid>
					<Grid item sm={6}>
						<TextInput fullWidth source="text" label="טקסט פתיחה" multiline />
					</Grid>
					<Grid item sm={2}>
						<BooleanInput label="שליחת טקסט פתיחה?" fullWidth source="send_welcome_text" />
					</Grid>
					<Box width="100%" />
					<Grid item sm={2}>
						<ArrayInput source="safe_space_prices" label="מחירי מרחב בטוח">
							<SimpleFormIterator
								inline
								disableClear
								fullWidth
								getItemLabel={(index: number) => <Typography width={60}>{`${index + 1}. `}</Typography>}
							>
								<NumberInput source="price" />
							</SimpleFormIterator>
						</ArrayInput>
					</Grid>
					<Box width="100%" />
					<Grid item sm={2}>
						<DateInput source="subscription_start" label="תחילת המנוי" />
					</Grid>
					<Grid item sm={2}>
						<BooleanInput source="active" label="חברה פעילה" defaultValue={true} />
					</Grid>
				</Grid>
			</SimpleForm>
		</Create>
	);
};

export default CompanyCreate;
