import { Button, Tooltip } from '@mui/material';
import moment from 'moment';
import { CgCheck, CgEye, CgInfo } from 'react-icons/cg';
import { IProactivePlanProgress } from '../../types';

const MissionsTable = ({
	plan,

	reveal,
	setAsCurrentWeek,
	setMissionDone,
}: {
	plan?: {
		startDate: string;
		points: number;
		consumedPoints: number;
		weekIndex: number;
		interval: number;
		plan: IProactivePlanProgress;
		show_onboarding?: boolean;
	};

	setAsCurrentWeek: (plan_id: string, week_id: string) => Promise<void>;
	reveal: (plan_id: string, week_id: string, reveal: boolean) => Promise<void>;
	setMissionDone: (v: { mission_id: string; is_complete: boolean }) => Promise<void>;
}) => {
	return (
		<table className="text-start table-auto mt-8">
			<thead className="bg-gray-200">
				<th />
				<th className="w-36">תאריך התחלה</th>
				<th className="w-20 text-center">זמינות</th>
				<th className="w-32 ">
					<Tooltip title='הכוונה שהמשתמש קיבל פוש/מייל על חשיפת התוכן הנ"ל'>
						<span className="flex justify-center">
							<span>חשיפה</span>
							<span>
								<CgInfo />
							</span>
						</span>
					</Tooltip>
				</th>
				<th className=" ">משימות</th>
			</thead>
			{plan?.plan.weekly_content?.map((week, weekIndex) => (
				<tr className="h-2" key={week._id}>
					<td className=" text-center">
						<Tooltip
							style={{ whiteSpace: 'pre-line' }}
							title={`הגדרת תאריך ההצגה של התכנים להיום והגדרת התוכן כ"זמין"`}
						>
							<span>
								<Button
									onClick={async () => {
										if (plan.plan._id && week._id !== undefined)
											await setAsCurrentWeek(plan.plan._id, week._id);
									}}
									disabled={weekIndex === plan.weekIndex}
								>
									סמן כנוכחי
								</Button>
							</span>
						</Tooltip>
					</td>
					<td className="text-center">{moment(week.start_date).format('DD/MM/YY')}</td>
					<td className="text-lg text-center">{week.available ? <CgCheck /> : ''}</td>
					<td className="">
						<div className="flex flex-col items-center mx-auto">
							{week.revealed ? <CgEye /> : ''}
							<button
								onClick={() => {
									if (plan.plan._id && week._id !== undefined)
										reveal(plan.plan._id, week._id, !week.revealed);
								}}
							>
								<div className="text-center text-sm underline">{`${week.revealed ? 'בטל חשיפה' : 'סמן כנחשף'}`}</div>
							</button>
						</div>
					</td>
					<td className="">
						<thead className="bg-gray-100 text-start">
							<th className="w-80 text-start">כותרת</th>
							<th className="w-28 text-center">סוג</th>
							<th className="pe-4">סטטוס</th>
							<th className="w-40 ">זמן ביצוע</th>
							<th />
						</thead>
						{week.missions.map((mission, missionIdx) => (
							<tr className="text-xs" key={mission._id}>
								<td className="pe-4 line-clamp-2 w-full text-sm">{mission.item?.title?.he}</td>
								<td className="text-center uppercase font-medium text-xs">{mission.item?.type}</td>
								<td className="text-sm ">{mission.is_done ? 'הושלם' : ''}</td>
								<td className="text-sm text-center">
									{mission.completed ? moment(mission.completed).format('D/M/YY H:mm') : ''}
								</td>
								<td className="text-sm text-center">
									<button
										className="underline py-0.5"
										onClick={() =>
											setMissionDone({
												mission_id: mission._id ?? '',
												is_complete: !Boolean(mission.is_done),
											})
										}
									>
										{mission.is_done ? 'בטל ביצוע משימה' : 'סמן כבוצע'}
									</button>
								</td>
							</tr>
						))}
					</td>
				</tr>
			))}
		</table>
	);
};

export default MissionsTable;
