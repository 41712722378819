import { Grid } from '@mui/material';
import { BooleanInput, Create, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';

const EmailGroupCreate = () => {
	return (
		<Create>
			<SimpleForm>
				<Grid container spacing={2}>
					<Grid item xs={12} md={2}>
						<TextInput source="groupId" fullWidth />
					</Grid>
					<Grid item xs={12} md={4}>
						<TextInput source="name" fullWidth />
					</Grid>
					<Grid item xs={12} md={2}>
						<BooleanInput source="allCompanies" />
					</Grid>
					<Grid item xs={12} md={6}>
						<ReferenceArrayInput
							perPage={999}
							sort={{ field: 'english_name', order: 'ASC' }}
							reference="company"
							source="excludeCompanies"
						>
							<SelectArrayInput label="חברות לא כלולות" optionText={'english_name'} fullWidth />
						</ReferenceArrayInput>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReferenceArrayInput
							perPage={999}
							sort={{ field: 'english_name', order: 'ASC' }}
							reference="company"
							source="includeCompanies"
						>
							<SelectArrayInput label="חברות כלולות" optionText={'english_name'} fullWidth />
						</ReferenceArrayInput>
					</Grid>
				</Grid>
			</SimpleForm>
		</Create>
	);
};

export default EmailGroupCreate;
