import { Card, CardContent, CardHeader, LinearProgress, Table, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';

const GroupSessionsCard = ({
	loading,
	todaysSessions,
	tomorrowSessions,
}: {
	loading: boolean;
	todaysSessions?: { time: string; title: string; registered: number }[];
	tomorrowSessions?: { time: string; title: string; registered: number }[];
}) => {
	return (
		<Card style={{ height: '100%' }}>
			<CardHeader title="הרצאות וסדנאות" />
			<CardContent>
				{loading ? (
					<LinearProgress />
				) : (
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell style={{ textAlign: 'right' }}>שעה</TableCell>
								<TableCell style={{ textAlign: 'right' }}>שם</TableCell>
								<TableCell style={{ textAlign: 'right' }}>רשומים</TableCell>
							</TableRow>
						</TableHead>
						<TableRow>
							<TableCell colSpan={3} style={{ textAlign: 'center', fontWeight: '600' }}>
								היום
							</TableCell>
						</TableRow>
						{todaysSessions
							?.filter((meeting) => moment(meeting.time).isSame(moment(), 'day'))
							.map((meeting) => (
								<TableRow>
									<TableCell style={{ textAlign: 'right' }}>
										{moment(meeting.time).format('HH:mm')}
									</TableCell>
									<TableCell style={{ textAlign: 'right' }}>{meeting.title}</TableCell>
									<TableCell style={{ textAlign: 'right' }}>{meeting.registered}</TableCell>
								</TableRow>
							))}
						<TableRow>
							<TableCell colSpan={3} style={{ textAlign: 'center', fontWeight: '600' }}>
								מחר
							</TableCell>
						</TableRow>
						{tomorrowSessions?.map((meeting) => (
							<TableRow>
								<TableCell style={{ textAlign: 'right' }}>
									{moment(meeting.time).format('HH:mm')}
								</TableCell>
								<TableCell style={{ textAlign: 'right' }}>{meeting.title}</TableCell>
								<TableCell style={{ textAlign: 'right' }}>{meeting.registered}</TableCell>
							</TableRow>
						))}
					</Table>
				)}
			</CardContent>
		</Card>
	);
};

export default GroupSessionsCard;
