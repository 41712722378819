import { Typography, useMediaQuery } from '@mui/material';
import {
	BooleanField,
	BooleanInput,
	Datagrid,
	DateField,
	List,
	NumberField,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	SimpleList,
	TextField,
	TextInput,
	useResourceContext,
} from 'react-admin';
import MyPagination from '../MyPagination';

const MemberList = () => {
	const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
	const context = useResourceContext({ resource: 'member' });

	return (
		<>
			<List
				filterDefaultValues={{ active: true }}
				perPage={50}
				filters={[
					<BooleanInput source="active" alwaysOn defaultChecked label="משתמשים פעילים" />,
					<ReferenceInput source="company" reference="company" perPage={1000}>
						<SelectInput optionText={'english_name'} />
					</ReferenceInput>,
					<TextInput source="email" />,
					<TextInput source="first_name" />,
					<TextInput source="last_name" />,
					<SelectInput
						emptyText={'הכל'}
						source="type"
						choices={[
							{ name: 'מנוי לידה', id: 'newborn' },
							{ name: 'התנסות', id: 'trial' },
							{ name: 'מומחה', id: 'expert' },
						]}
					/>,
				]}
				pagination={<MyPagination />}
			>
				{isSmall ? (
					<SimpleList
						resource={context}
						sx={{ textAlign: 'right' }}
						style={{ textAlign: 'right' }}
						primaryText={(record) => (
							<Typography textAlign="right" fontWeight={'600'}>
								{`${record.email}`}
							</Typography>
						)}
						secondaryText={(record) => <Typography textAlign="left">{record.type}</Typography>}
					/>
				) : (
					<Datagrid
						rowClick="show"
						bulkActionButtons={false}
						dir="rtl"
						sx={{
							'& .column-first_name,.column-last_name, .column-email, .column-phone': { maxWidth: 250 },
						}}
					>
						<TextField
							source="first_name"
							label="שם פרטי"
							textAlign="right"
							style={{ display: 'block', overflow: 'hidden' }}
						/>
						<TextField
							source="last_name"
							label="שם משפחה"
							textAlign="right"
							fullWidth
							style={{ display: 'block', overflow: 'hidden' }}
						/>
						<TextField
							source="email"
							label="מייל"
							textAlign="right"
							padding={0}
							margin={0}
							fullWidth
							style={{ display: 'block', overflow: 'hidden' }}
						/>

						<ReferenceField reference="company" source="company" label="חברה" textAlign="right">
							<TextField source="english_name" fullWidth />
						</ReferenceField>
						<BooleanField source="email_verified" label="מייל מאומת" textAlign="right" fullWidth />
						<DateField
							showTime
							locales={'he'}
							source="lastLoginTime"
							label="התחברות אחרונה"
							textAlign="right"
							fullWidth
						/>
						<TextField source="platform" label="מערכת" textAlign="center" fullWidth />
						<TextField source="version" label="גרסה מותקנת" textAlign="center" fullWidth />
						<BooleanField source="is_new" label="משתמש חדש" textAlign="center" fullWidth />
						<BooleanField source="push_token" label="Push Token?" textAlign="right" looseValue fullWidth />
						<NumberField source="safe_space_count" label="מפגשי מרחב בטוח" textAlign="center" fullWidth />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default MemberList;
