import { Grid } from '@mui/material';
import {
	Datagrid,
	DateField,
	FunctionField,
	Labeled,
	NumberField,
	ReferenceArrayField,
	ReferenceField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField,
	useShowController,
} from 'react-admin';
import { ICoupon } from '../../types';

const CouponShow = () => {
	const record = useShowController();
	const coupon = record.record as ICoupon;
	return (
		<Show>
			<TabbedShowLayout>
				<Tab label={'פרטים'}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Labeled label="קוד קופון">
								<TextField source="code" fontSize={18} fontWeight={700} />
							</Labeled>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Labeled label="מוצר">
								<TextField source="product" />
							</Labeled>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Labeled label="שווי קופון">
								<FunctionField
									render={(record: any) =>
										`${record.discountValue}${record.discountType === 'fixed' ? '₪' : '%'}`
									}
								/>
							</Labeled>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Labeled label="שימושים">
								<NumberField source="usedCount" />
							</Labeled>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Labeled label="פעיל">
								<TextField source="active" />
							</Labeled>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Labeled label="תאריך תפוגה">
								<DateField source="expirationDate" />
							</Labeled>
						</Grid>
					</Grid>
				</Tab>
				<Tab label={'משתמשים'}>
					<Grid item xs={12} sm={6}>
						{coupon?.usedBy_users?.length ? (
							<ReferenceArrayField reference="member" source="usedBy_users">
								<Datagrid bulkActionButtons={false}>
									<ReferenceField source="_id" reference="member" label="משתמשים">
										<TextField source="email" label="שומש" />
									</ReferenceField>
								</Datagrid>
							</ReferenceArrayField>
						) : null}
					</Grid>
				</Tab>
				<Tab label={'אורחים'}>
					{coupon?.usedBy_guests?.length ? (
						<div className="mt-4">
							<h5 className="text-start px-4 font-medium text-sm">אורחים</h5>
							<table className="mb-4 px-4">
								<tbody>
									{coupon?.usedBy_guests?.map((email: string) => (
										<tr className="px-4">
											<td className="px-4">{email}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : null}
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export default CouponShow;
