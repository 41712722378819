import { Button, Card, CardContent, CardHeader, MenuItem, Select } from '@mui/material';

const SettingsCard = ({
	company,
	companies,
	expertId,
	expertStats,
	handleChangeMyCompany,
	handleChangeMyExpert,
	handleCopyNoWixEmails,
	handleCopyNewbornEmails,
	activeNewborns,
	setShowKidsDOBModal,
}: {
	company: string;
	companies: any[];
	expertId: string;
	expertStats: any;
	handleChangeMyCompany: (company: string) => void;
	handleChangeMyExpert: (expertId: string) => void;
	handleCopyNoWixEmails: () => void;
	handleCopyNewbornEmails: () => void;
	activeNewborns: any;
	setShowKidsDOBModal: (show: boolean) => void;
}) => {
	return (
		<Card style={{ height: '100%' }}>
			<CardHeader title="הגדרות שלי" />
			<CardContent>
				<table>
					<thead>
						<th className="w-full" />
						<th />
					</thead>
					<tr>
						<td className="td-row-title">בחירת חברה</td>
						<td>
							<Select
								className="w-full"
								size={'small'}
								displayEmpty
								label="החברה שלי"
								value={company}
								onChange={(e) => {
									handleChangeMyCompany(e.target.value);
								}}
							>
								{companies?.map((company: any) => (
									<MenuItem value={company?._id}>{company.english_name}</MenuItem>
								))}
							</Select>
						</td>
					</tr>
					<tr>
						<td className="td-row-title">בחירת עמוד יועץ</td>
						<td>
							<Select
								className="w-full"
								size="small"
								displayEmpty
								label=""
								value={expertId}
								onChange={(e) => {
									handleChangeMyExpert(e.target.value);
								}}
							>
								<MenuItem value={'null'}>ללא</MenuItem>
								{expertStats.data?.experts.map((expert: any) => (
									<MenuItem value={expert?._id}>{expert.name}</MenuItem>
								))}
							</Select>
						</td>
					</tr>
					<tr>
						<td className="td-row-title">
							מיילים עובדי חברות
							<p className="text-xs leading-tight"> (ללא Wix & MyHeritage)</p>
						</td>
						<td>
							<Button onClick={handleCopyNoWixEmails}>{'העתקה'}</Button>
						</td>
					</tr>
					<tr>
						<td className="td-row-title">מיילים מנוי לידה</td>
						<td>
							<Button disabled={Boolean(activeNewborns.loading)} onClick={handleCopyNewbornEmails}>
								העתקה
							</Button>
						</td>
					</tr>
					<tr>
						<td className="td-row-title">העתקה לפי גיל ילדים</td>
						<td>
							<Button onClick={() => setShowKidsDOBModal(true)}>העתקה</Button>
						</td>
					</tr>
				</table>
			</CardContent>
		</Card>
	);
};

export default SettingsCard;
