import { Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import {
	BooleanInput,
	Edit,
	ImageField,
	ImageInput,
	NumberInput,
	SimpleForm,
	TextInput,
	useEditController,
	useRefresh,
} from 'react-admin';
import { CgCheck } from 'react-icons/cg';
import constants from '../../constants';

const PrizeEdit = () => {
	const { record, refetch } = useEditController();
	const refresh = useRefresh();
	const handleResetCoupon = async (couponId: string) => {
		await axios.put(`${constants.API_URL}/prize/${record._id}/reset-coupon`, { coupon: couponId });
		refetch();
		refresh();
	};

	const [couponToAdd, setCouponToAdd] = useState('');
	const handleAddCoupon = async () => {
		try {
			await axios.put(`${constants.API_URL}/prize/${record._id}/add-coupon-code`, { code: couponToAdd });
			setCouponToAdd('');
			refetch();
			refresh();
		} catch (error) {}
	};
	return (
		<Edit>
			<SimpleForm>
				<div className="w-full">
					<div>
						<img src={record?.imageUrl} className="w-40 h-40 object-contain" />
					</div>
					<div className="gap-4 flex flex-row">
						<TextInput source="title.he" className="inline" />
						<TextInput source="title.en" className="inline" />
					</div>

					<div className="gap-4 flex flex-row">
						<TextInput source="subTitle.he" className="inline" />
						<TextInput source="subTitle.en" className="inline" />
					</div>
					<div className="gap-4 flex flex-row">
						<TextInput source="description.he" className="inline" />
						<TextInput source="description.en" className="inline" />
					</div>
					<div className="gap-4 flex flex-row">
						<NumberInput source="points" className="inline" />
						<TextInput source="imageUrl" className="inline" fullWidth />
						<TextInput source="thumbnail" className="inline" fullWidth />
						<TextInput source="baseLink" className="inline" fullWidth />
					</div>
					<Grid item sm={12}>
						<ImageInput source="image" accept={[`image/*`]}>
							<ImageField source="src" />
						</ImageInput>
					</Grid>
					<div className="gap-4 flex flex-row">
						<BooleanInput
							label="שרשור קוד הקופון ל-URL"
							source="isURLCoupon"
							helperText="במידה וכן, הקישור שיישלח למשתמש יהיה בפורמט baseLink+code. במידה ולא, הקישור יהיה לbaseLink וקוד הקופון ייכתב כטקסט בגוף המייל"
						/>
						<NumberInput source="order" className="inline" label="סדר" />
					</div>

					<div className="mt-8">
						<table className="border-separate border-spacing-x-8 border-spacing-y-1 text-start ">
							<thead>
								<th className="w-8" />
								<th>קוד קופון</th>
								<th>מומש?</th>
								<th>תאריך מימוש</th>
								<th className="w-8" />
							</thead>
							{record?.coupons?.map((coupon: any, index: number) => (
								<tr className="">
									<td>{index + 1}</td>
									<td>{coupon.code}</td>
									<td className="text-center items-center flex justify-center">
										{coupon.redeemed ? <CgCheck /> : null}
									</td>
									<td>{coupon.redeemedAt ? moment(coupon.redeemedAt).format('DD/MM/YY') : ''}</td>
									<td>
										{coupon.redeemed ? (
											<Button
												size="small"
												onClick={() => {
													handleResetCoupon(coupon._id);
												}}
											>
												איפוס
											</Button>
										) : null}
									</td>
								</tr>
							))}
							<tr>
								<td />
								{/* <td className='text-nowrap'>{'הוספת קוד:'}</td> */}
								<td className="">
									<TextField
										fullWidth
										variant="standard"
										size="small"
										onChange={(e) => setCouponToAdd(e.target.value)}
										value={couponToAdd}
									/>
								</td>

								<td>
									<Button
										onClick={() => {
											handleAddCoupon();
										}}
									>
										הוספה
									</Button>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<BooleanInput source="visible" />
			</SimpleForm>
		</Edit>
	);
};

export default PrizeEdit;
