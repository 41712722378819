import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import constants from '../../constants';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectPrivateService = ({
	onExpertChange,
	onServiceChange,
	selectedExpertId,
	selectedServiceId,
	horizontal,
	disabled = false,
}: {
	onExpertChange: (expert_id: string) => void;
	onServiceChange: (expert_id: string) => void;

	selectedExpertId: string | null;
	selectedServiceId: string | null;
	horizontal?: boolean;
	disabled?: boolean;
}) => {
	const experts = useGetList('instructor/all', {
		pagination: { perPage: 1000, page: 1 },
		sort: { field: 'name', order: 'ASC' },
	});
	const [services, setServices] = useState<any[]>([]);

	useEffect(() => {
		const getServices = async () => {
			setServices([]);

			setServices(
				(
					await axios.get(`${constants.API_URL}/instructor/private_services/all`, {
						params: { instructor_id: selectedExpertId },
					})
				).data,
			);
		};
		getServices();
	}, [selectedExpertId]);

	return (
		<div style={{ display: 'flex', flexDirection: horizontal ? 'row' : 'column', gap: 20 }}>
			<FormControl>
				<InputLabel id="demo-simple-select-label">מומחה</InputLabel>
				<Select
					disabled={disabled}
					size="small"
					label="מומחה"
					style={{ minWidth: 300 }}
					value={selectedExpertId}
					onChange={(e, v) => {
						onExpertChange(e.target.value as string);
					}}
				>
					{experts.data?.map((expert: any) => <MenuItem value={expert._id}>{expert.name}</MenuItem>)}
				</Select>
			</FormControl>
			<FormControl>
				<InputLabel id="demo-simple-select-label">שירות</InputLabel>
				<Select
					disabled={disabled}
					size="small"
					label="שירות"
					style={{ minWidth: 300 }}
					value={selectedServiceId}
					onChange={(e, v) => {
						onServiceChange(e.target.value as string);
					}}
				>
					{services?.map((expert: any) => (
						<MenuItem value={expert._id}>
							<span>
								{`${expert.title.he}`}
								<span style={{ fontSize: 10 }}>{` ${expert.duration} דקות`}</span>
							</span>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};
export default SelectPrivateService;
