import { Container, Grid, IconButton } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useGetList } from 'react-admin';
import { BiSolidTv, BiTv } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CopyByDateOfBirthDialog from '../components/CopyByDateOfBirthDialog';
import ExpertsCard from './ExpertsCard';
import GroupSessionsCard from './GroupSessionsCard';
import MembersCard from './MembersCard';
import MoreInfoCard from './MoreInfoCard';
import NPSCard from './NPSCard';
import PassItOnStatsCard from './PassItOnStatsCard';
import RandomPassItOnCard from './RandomPassItOnCard';
import SettingsCard from './SettingsCard';
import useDashboard from './useDashboard';

export const Dashboard = () => {
	const [showKidsDOBModal, setShowKidsDOBModal] = useState(false);
	const [isTVMode, setIsTVMode] = useState(false);
	const navigation = useNavigate();
	const {
		handleChangeMyCompany,
		handleChangeMyExpert,
		handleCopyNewbornEmails,
		handleCopyNoWixEmails,
		activeNewborns,
		company,
		groupSessionsStats,
		newbornSentCount,
		expertId,
		expertStats,
		passItOnStats,
		randomPassItOnStats,
		totalPrivateSessions,
		totalPrivateSessionsToday,
		memberStats,
		dating,
		nps,
	} = useDashboard({ pollingInterval: isTVMode ? 30000 : null });
	const companies = useGetList('company', {
		pagination: { perPage: 1000, page: 1 },
		sort: { field: 'english_name', order: 'ASC' },
	});
	const cards: { component: ReactNode; xs: number; sm: number; showInTvMode?: boolean; showInPCMode?: boolean }[] = [
		{
			xs: 12,
			sm: 4,
			component: (
				<SettingsCard
					company={company}
					companies={companies.data ?? []}
					expertId={expertId}
					expertStats={expertStats}
					handleChangeMyCompany={handleChangeMyCompany}
					handleChangeMyExpert={handleChangeMyExpert}
					handleCopyNoWixEmails={handleCopyNoWixEmails}
					handleCopyNewbornEmails={handleCopyNewbornEmails}
					activeNewborns={activeNewborns}
					setShowKidsDOBModal={setShowKidsDOBModal}
				/>
			),
			showInTvMode: false,
		},
		{
			xs: 12,
			sm: 4,
			component: (
				<div style={{ position: 'relative' }}>
					<div className="justify-center items-center flex absolute -top-10 ">
						<img src="transparent-logo.gif" style={{}} alt='logo'/>
					</div>
				</div>
			),
			showInTvMode: true,
			showInPCMode: false,
		},
		{
			xs: 12,
			sm: 4,
			component: (
				<MembersCard
					navigation={navigation}
					memberStats={memberStats}
					totalUsers={memberStats.data?.totalUsers}
					todayUsers={memberStats.data?.todayUsers}
					newUsers={memberStats.data?.newUsers}
					groupSessionsStatsLoading={groupSessionsStats.loading}
					todaysEnrollments={groupSessionsStats.data?.todaysEnrollments}
					totalPrivateSessionsToday={totalPrivateSessionsToday.data?.data}
					totalPrivateSessions={totalPrivateSessions?.data?.data}
				/>
			),
		},
		{ xs: 12, sm: 4, component: <NPSCard nps={nps} /> },
		{
			xs: 12,
			sm: 4,
			component: (
				<PassItOnStatsCard
					stats={passItOnStats.data?.stats}
					totalCount={passItOnStats.data?.totalCount}
					totalChange={passItOnStats.data?.totalChange}
				/>
			),
		},
		{
			xs: 12,
			sm: 8,
			component: (
				<RandomPassItOnCard
					isTVMode={isTVMode}
					data={randomPassItOnStats.data}
					fetchAgain={randomPassItOnStats.fetchAgain}
					isFetching={randomPassItOnStats.isFetching}
				/>
			),
		},
		{
			xs: 12,
			sm: 4,
			component: (
				<ExpertsCard
					loading={expertStats.loading}
					total={expertStats.data?.total}
					registeredExperts={expertStats.data?.registeredExperts}
					synchedExperts={expertStats.data?.synchedExperts}
					safeSpaceExperts={expertStats.data?.safeSpaceExperts}
				/>
			),
		},
		{
			xs: 12,
			sm: 4,
			component: (
				<MoreInfoCard
					title="נתונים נוספים"
					info={[
						{ title: 'מנויי לידה', value: newbornSentCount.data?.count },
						{ title: 'רשומים לדייטינג בהייטק', value: dating.data },
						{ title: 'NPS', value: nps },
					]}
				/>
			),
		},

		{
			xs: 12,
			sm: 4,
			component: (
				<GroupSessionsCard
					loading={groupSessionsStats.loading}
					todaysSessions={groupSessionsStats.data?.todaysSessions}
					tomorrowSessions={groupSessionsStats.data?.tomorrowSessions}
				/>
			),
		},
	];
	return (
		<>
			<CopyByDateOfBirthDialog open={showKidsDOBModal} onDismiss={() => setShowKidsDOBModal(false)} />
			<div>
				<div className="absolute top-10 left-0 p-4">
					<IconButton
						color={isTVMode ? 'primary' : 'default'}
						title="מצב טלויזיה"
						onClick={() => {
							setIsTVMode(!isTVMode);
						}}
					>
						{isTVMode ? <BiSolidTv /> : <BiTv />}
					</IconButton>
				</div>
				<Container style={{ paddingTop: 30 }} maxWidth={isTVMode ? 'xl' : 'lg'}>
					<Grid container spacing={2}>
						{cards
							.filter(
								(x) => (!isTVMode && x.showInPCMode !== false) || (isTVMode && x.showInTvMode !== false)
							)
							.map((card, index) => (
								<Grid
									item
									xs={card.xs}
									sm={card.sm}
									key={index}
									height={'100%'}
									style={{ height: 300, overflowY: 'scroll' }}
								>
									{card.component}
								</Grid>
							))}
					</Grid>
				</Container>
			</div>
		</>
	);
};
