import {
	Button,
	Dialog,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	Select,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { useEffect, useReducer, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import constants from '../../../constants';
import { getLocalizedErrorMessage } from '../../../utils/functions';
import SelectPrivateService from '../SelectPrivateService';

type Action =
	| { type: 'SET_EXPERT'; payload: string }
	| { type: 'SET_SERVICE'; payload: string }
	| { type: 'SET_SLOT'; payload: string | null }
	| { type: 'SET_EMAIL'; payload: string }
	| { type: 'SET_COUPON'; payload: string }
	| { type: 'SET_IS_RETROACTIVE'; payload: boolean };
type State = {
	expert_id: string | null;
	service: string | null;
	slot: string | null;
	email: string;
	retroactiveSession: boolean;
	coupon?: string;
};

const CreatePrivateSessionDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
	const redirect = useRedirect();
	const initialState: State = { expert_id: null, service: null, slot: null, email: '', retroactiveSession: true };
	const reducer = (state: State, action: Action) => {
		switch (action.type) {
			case 'SET_EXPERT':
				return { ...state, expert_id: action.payload, service: null, slot: null };
			case 'SET_SERVICE':
				return { ...state, service: action.payload, slot: null };
			case 'SET_SLOT':
				return { ...state, slot: action.payload };
			case 'SET_EMAIL':
				return { ...state, email: action.payload };
			case 'SET_IS_RETROACTIVE':
				return { ...state, retroactiveSession: action.payload };
			case 'SET_COUPON':
				return { ...state, coupon: action.payload };
			default:
				return state;
		}
	};
	const [date, setDate] = useState(new Date());
	const [slots, setSlots] = useState<any[]>([]);
	const [state, dispatch] = useReducer(reducer, initialState);
	useEffect(() => {
		const getSlots = async () => {
			setSlots([]);
			setSlots(
				(
					await axios.post(
						`${constants.API_URL}/instructor/freebusy/v2`,
						{ id: state.service, followup: false },
						{
							params: {
								from_date: moment(date).startOf('day').toDate(),
								to_date: moment(date).endOf('day').toDate(),
							},
							data: { id: state.service, followup: false },
						},
					)
				).data[0].availableSlots,
			);
		};
		getSlots();
	}, [date, state.service, state.expert_id]);
	const notify = useNotify();

	console.log({ time: state.slot });
	const handleSubmit = async () => {
		try {
			const url = `${constants.API_URL}/instructor/private_services/session${state.retroactiveSession ? '/retroactive/' : ''}`;
			const data = {
				email: state.email,
				service_id: state.service,
				time: state.slot,
				follow_up_accepted: false,
				coupon: state.coupon,
			};
			const response = await axios.post(url, data);
			notify(response.data.message ?? 'SUCCESS', { type: 'success' });
			redirect('list', 'instructor/private_services/sessions');
			onClose();
		} catch (error) {
			notify(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
		}
	};

	return (
		<Dialog open={open} style={{ width: '90%' }} onClose={onClose}>
			<div style={{ marginBlock: 10, textAlign: 'center' }}>
				<ToggleButtonGroup
					dir="ltr"
					exclusive
					value={state.retroactiveSession}
					onChange={(e, v) => {
						dispatch({ type: 'SET_IS_RETROACTIVE', payload: v });
					}}
				>
					<ToggleButton value={true}>פגישת עבר</ToggleButton>
					<ToggleButton value={false}>עתידית</ToggleButton>
				</ToggleButtonGroup>
			</div>
			<div style={{ padding: 20 }}>
				<SelectPrivateService
					selectedExpertId={state.expert_id}
					selectedServiceId={state.service}
					onExpertChange={(expert_id) => {
						dispatch({ type: 'SET_EXPERT', payload: expert_id });
					}}
					onServiceChange={(service_id) => {
						dispatch({ type: 'SET_SERVICE', payload: service_id });
					}}
				/>

				{state.service ? (
					<div style={{ display: 'flex', flexDirection: 'row', gap: 20, marginBottom: 10, marginBlock: 10 }}>
						<Input
							type="date"
							value={moment(date).format('yyyy-MM-DD')}
							onChange={(e) => {
								const date = new Date(e.target.value);
								if (
									(state.retroactiveSession && moment(date).isBefore(moment())) ||
									(!state.retroactiveSession && moment(date).isAfter(moment()))
								)
									setDate(date);
								dispatch({ type: 'SET_SLOT', payload: null });
							}}
							style={{}}
						/>
						{state.retroactiveSession ? (
							<FormControl style={{ display: 'flex', flex: 1 }}>
								<InputLabel>שעה</InputLabel>
								<Input
									type="time"
									value={moment(state.slot).format('HH:mm')}
									onChange={(e) => {
										const [hour, minute] = e.target.value.split(':');
										dispatch({
											type: 'SET_SLOT',
											payload: moment(date)
												.set('hour', Number.parseInt(hour))
												.set('minute', Number.parseInt(minute))
												.toISOString(),
										});
									}}
								/>
							</FormControl>
						) : (
							<FormControl style={{ display: 'flex', flex: 1 }}>
								<InputLabel>שעות פנויות</InputLabel>
								<Select
									margin="none"
									size="small"
									value={state.slot}
									onChange={(e) => {
										dispatch({ type: 'SET_SLOT', payload: e.target.value as string });
									}}
								>
									{slots.map((x) => (
										<MenuItem value={x.start}>{moment(x.start).format('HH:mm')}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					</div>
				) : null}
				<div style={{ width: '100%', display: 'flex', marginBottom: 10, flexDirection: 'column', gap: 12 }}>
					<Input
						style={{ width: '100%', paddingInline: 20 }}
						placeholder="מייל"
						type="email"
						value={state.email}
						onChange={(e) => {
							dispatch({ type: 'SET_EMAIL', payload: e.target.value });
						}}
					/>
					<Input
						style={{ width: '100%', paddingInline: 20 }}
						type="text"
						placeholder="קופון"
						value={state.coupon}
						onChange={(e) => {
							dispatch({ type: 'SET_COUPON', payload: e.target.value });
						}}
					/>
				</div>
				<div>
					<Button
						variant="outlined"
						disabled={!(state.email && state.expert_id && state.service && state.slot)}
						onClick={handleSubmit}
					>
						קביעת פגישה
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default CreatePrivateSessionDialog;
