import { Button, Dialog } from '@mui/material';
import { useState } from 'react';

const AddDomainModal = ({
	handleAdd,
	visible,
	onDismiss,
	domain: initDomain = null,
	handleAddDomainEmailVerifier,
}: {
	visible: boolean;
	handleAdd: (domain: string, verifierEmail: string) => Promise<void>;
	onDismiss: () => void;
	domain?: null | { _id: string; domain: string };
	handleAddDomainEmailVerifier: (domain_id: string, email: string) => Promise<void>;
}) => {
	const [domain, setDomain] = useState<null | string>(initDomain?.domain ?? null);
	const [verifierEmail, setVerifierEmail] = useState('');
	return (
		<Dialog title="Add Domain" open={visible} PaperProps={{ style: { borderRadius: 20 } }} onClose={onDismiss}>
			<div className="p-8 flex flex-col gap-2 ">
				<input
					disabled={initDomain !== null}
					placeholder="Domain"
					className="p-2 border-b text-left w-[200px]"
					onChange={(e) => setDomain(e.target.value)}
					value={domain ?? ''}
				/>
				<input
					placeholder="Email for verification"
					className="p-2 border-b text-left  w-[200px]"
					onChange={(e) => setVerifierEmail(e.target.value)}
					value={verifierEmail}
				/>
				<Button
					type="button"
					onClick={async () => {
						if (initDomain) await handleAddDomainEmailVerifier(initDomain._id, verifierEmail);
						else if (domain) await handleAdd(domain, verifierEmail);
						onDismiss();
					}}
				>
					הוספה
				</Button>
			</div>
		</Dialog>
	);
};

export default AddDomainModal;
