import { Box, Button, Grid, Stack } from '@mui/material';
import axios, { AxiosError } from 'axios';
import {
	AlignmentButtons,
	ClearButtons,
	ColorButtons,
	FormatButtons,
	ImageButtons,
	LevelSelect,
	LinkButtons,
	ListButtons,
	QuoteButtons,
	RichTextInput,
	RichTextInputToolbar,
} from 'ra-input-rich-text';
import { useCallback, useState } from 'react';
import {
	Datagrid,
	Edit,
	FormTab,
	NumberInput,
	ReferenceManyField,
	SaveButton,
	TabbedForm,
	TextField,
	Toolbar,
	useEditController,
	useNotify,
	usePermissions,
} from 'react-admin';
import constants from '../../constants';
import { getLocalizedErrorMessage } from '../../utils/functions';
import MyPagination from '../MyPagination';
import AddDomainModal from './AddDomainModal';
import AuthOptions from './AuthOptions';
import DeleteCompanyDialog from './DeleteComapnyDialog';
import FactorsModal from './FactorsModal';
import GeneralCompanyInfo from './GeneralCompanyInfo';
import HREdit from './HR/HREdit';
import MiluimForm from './Miluim/MiluimForm';

const CompanyToolBar = (props: any) => (
	<Toolbar>
		<SaveButton />
	</Toolbar>
);
const CompanyInfoText = (record: any) => {
	return (
		<Stack>
			<RichTextInput source="subscription_info.he" label="מה מגיע לי (עברית)" />
			<RichTextInput source="subscription_info.en" label="מה מגיע לי (אנגלית)" textAlign={'right'} />
		</Stack>
	);
};

const CompanyEmployees = () => (
	<div>
		<h3>עובדי חברה</h3>
		<ReferenceManyField pagination={<MyPagination />} perPage={20} reference="member" target="company">
			<Datagrid bulkActionButtons={false} rowClick="show">
				<TextField source="email" />
				<TextField source="first_name" />
				<TextField source="last_name" />
			</Datagrid>
		</ReferenceManyField>
	</div>
);

const CompanyEdit = () => {
	const [addDomainModalVisible, setAddDomainModalVisible] = useState(false);

	const { permissions } = usePermissions();
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const handleDeleteCompanyButtonPress = () => {
		setShowDeleteDialog(true);
	};

	const { record, refetch } = useEditController();
	const notify = useNotify();

	const handleDeleteConfirm = async (confirm: string) => {
		try {
			const res = await axios.delete(`${constants.API_URL}/company/${record._id}/delete_users`, {
				data: { company_id: record._id, verification: confirm },
			});
			notify(`${res.data?.count || 'כל'} עובדי ${record?.english_name} נמחקו`);
			handleCloseDialog();
		} catch (error) {}
	};
	const handleCloseDialog = () => {
		setShowDeleteDialog(false);
	};

	const [showUdiFactorDialog, setShowUdiFactorDialog] = useState(false);
	const udiFactors = record?.udiFactors as Map<string, { value: number; updatedAt: Date; active: boolean }>;

	const handleAddDomain = useCallback(
		async (domain: string, verifierEmail: string) => {
			try {
				await axios.post(`${constants.API_URL}/company/${record._id}/domain`, { domain, verifierEmail });
				refetch();
			} catch (error) {
				notify(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
				throw error;
			}
		},
		[record?._id, refetch, notify]
	);
	const handleAddDomainEmailVerifier = useCallback(
		async (domain_id: string, verifierEmail: string) => {
			try {
				await axios.post(`${constants.API_URL}/company/${record._id}/domain/add-email`, {
					domain_id,
					verifierEmail,
				});
				refetch();
			} catch (error) {
				notify(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
				throw error;
			}
		},
		[record?._id, refetch, notify]
	);

	const [domainToEdit, setDomainToEdit] = useState<null | { _id: string; domain: string }>(null);

	const showUpdateVerifierModal = (domain_id: string, domain: string) => {
		setDomainToEdit({ _id: domain_id, domain });
		setAddDomainModalVisible(true);
	};
	const showAddDomainModal = () => {
		setDomainToEdit(null);
		setAddDomainModalVisible(true);
	};
	return (
		<>
			<AddDomainModal
				key={domainToEdit?._id}
				domain={domainToEdit}
				handleAddDomainEmailVerifier={handleAddDomainEmailVerifier}
				handleAdd={handleAddDomain}
				visible={addDomainModalVisible}
				onDismiss={() => {
					setAddDomainModalVisible(false);
				}}
			/>
			<FactorsModal
				onClose={() => {
					setShowUdiFactorDialog(false);
				}}
				open={showUdiFactorDialog}
				onFactorUpdate={async (name: string, value: number) => {
					try {
						await axios.patch(`${constants.API_URL}/company/${record._id}/udi_update_factor`, {
							name,
							value,
						});
						setShowUdiFactorDialog(false);
						notify('בוצע בהצלחה', { type: 'success' });
						refetch();
					} catch (error) {
						notify(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
					}
				}}
			/>
			<Edit mutationMode="pessimistic">
				<DeleteCompanyDialog
					companyName={record?.english_name}
					handleCloseDialog={() => {
						setShowDeleteDialog(false);
					}}
					isVisible={showDeleteDialog}
					handleDeleteConfirm={handleDeleteConfirm}
				/>
				<TabbedForm toolbar={<CompanyToolBar />}>
					<FormTab label={'מידע כללי'}>
						<GeneralCompanyInfo
							record={record}
							onAddUdiFactorsButtonPress={() => {
								setShowUdiFactorDialog(true);
							}}
							refetch={refetch}
							udiFactors={udiFactors}
						/>
					</FormTab>
					<FormTab label="הזדהות">
						<AuthOptions
							record={record}
							showUpdateVerifierModal={showUpdateVerifierModal}
							showAddDomainModal={showAddDomainModal}
							refetch={refetch}
						/>
					</FormTab>
					<FormTab label={'מה מגיע לי'}>
						<CompanyInfoText />
					</FormTab>
					<FormTab label="HR">{record?._id ? <HREdit company_id={record?._id} /> : null}</FormTab>
					<FormTab label={'נתונים למתחם HR'}>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<NumberInput source="satisfaction" step={0.1} label="מדד שביעות רצון" fullWidth />
							</Grid>
							<Grid item xs={3}>
								<NumberInput source="perceived_value" step={0.1} label="הערך הנתפס" fullWidth />
							</Grid>
							<Box width="100%" />
							<Grid item xs={3}>
								<NumberInput fullWidth source="old_platform_subscribers" label="משתמשים באתר הישן" />
							</Grid>
							<Grid item xs={3}>
								<NumberInput fullWidth source="computed_usages" label="מס' שימושים משוקלל" />
							</Grid>
							<Grid item xs={12}>
								<RichTextInput
									fullWidth
									source="executiveSummary"
									label="תקציר מנהלים"
									toolbar={
										<RichTextInputToolbar>
											<LevelSelect size={'medium'} />
											<FormatButtons size={'medium'} />
											<AlignmentButtons size="medium" />
											<ColorButtons size={'medium'} />
											<ListButtons size={'medium'} />
											<LinkButtons size={'medium'} />
											<ImageButtons size={'medium'} />
											<QuoteButtons size={'medium'} />
											<ClearButtons size={'medium'} />
										</RichTextInputToolbar>
									}
								/>
							</Grid>
						</Grid>
					</FormTab>
					<FormTab label={'מילואימניקים'}>
						<MiluimForm />
					</FormTab>
					<FormTab label={'עובדים'}>
						<CompanyEmployees />
					</FormTab>
					{permissions === 'superadmin' && (
						<FormTab borderColor={'red'} label="סכנה">
							<Button onClick={handleDeleteCompanyButtonPress} color="error" variant="contained">
								מחיקת חברה
							</Button>
						</FormTab>
					)}
				</TabbedForm>
			</Edit>
		</>
	);
};

export default CompanyEdit;
