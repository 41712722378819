import { ChipField, Datagrid, ImageField, List, ReferenceArrayField, SingleFieldList, TextField } from 'react-admin';

const LectureList = () => {
	return (
		<List>
			<Datagrid rowClick="edit">
				<TextField source="title.he" textAlign="right" label="כותרת" />
				<TextField source="expert_name.he" textAlign="right" label="שם מרצה" />
				<ReferenceArrayField textAlign="right" reference="lecture/category" source="category" label="קטגוריות">
					<SingleFieldList>
						<ChipField source="name.he" />
					</SingleFieldList>
				</ReferenceArrayField>
				<ImageField textAlign="right" source="image" label="תמונה" />
			</Datagrid>
		</List>
	);
};

export default LectureList;
