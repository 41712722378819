import { IconButton, Typography, useMediaQuery } from '@mui/material';
import {
	BooleanField,
	ChipField,
	CreateButton,
	Datagrid,
	ExportButton,
	FilterButton,
	FunctionField,
	List,
	NumberField,
	ReferenceArrayField,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	SimpleList,
	SingleFieldList,
	TextField,
	TextInput,
	TopToolbar,
	useNotify,
	useResourceContext,
} from 'react-admin';
import MyPagination from '../MyPagination';
import { BiLinkAlt } from 'react-icons/bi';

const VodList = () => {
	const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
	const context = useResourceContext({ resource: 'vod' });
	const notify = useNotify();
	return (
		<List
			pagination={<MyPagination />}
			perPage={100}
			resource="vod/all"
			sx={{ direction: 'rtl' }}
			actions={
				<TopToolbar>
					<CreateButton resource="vod" />
					<ExportButton />
					<FilterButton />
				</TopToolbar>
			}
			filters={[
				<TextInput source="title" />,
				<TextInput source="sub_title" />,
				<ReferenceInput label="קטגוריה" source="category" reference="vod/categories/">
					<SelectInput optionText={'hebrew_name'} />
				</ReferenceInput>,
			]}
		>
			{isSmall ? (
				<SimpleList
					resource={context}
					sx={{ textAlign: 'right' }}
					style={{ textAlign: 'right' }}
					primaryText={(record) => (
						<Typography textAlign="right" fontWeight={'600'}>
							{`${record.title}-${record.sub_title}`}
						</Typography>
					)}
					secondaryText={(record) => <Typography textAlign="left">{record.category}</Typography>}
				/>
			) : (
				<Datagrid rowClick="edit" dir="rtl" resource={context}>
					<BooleanField source="visible" label="זמין" textAlign="right" />
					<TextField source="title" label="שם" textAlign="right" />
					<TextField source="sub_title" label="תת כותרת" textAlign="right" />
					<ReferenceArrayField
						source="category"
						reference="vod/categories/all"
						textAlign="center"
						label="קטגוריה"
					>
						<SingleFieldList>
							<ChipField source="hebrew_name" />
						</SingleFieldList>
					</ReferenceArrayField>
					<ReferenceField source="recorded_meeting" reference="meeting" textAlign="center" label="מפגש מוקלט">
						<ChipField source="title" />
					</ReferenceField>
					<NumberField source="priority" label="קדימות" fullWidth />
					<NumberField source="views" label="צפיות" fullWidth />
					<FunctionField
						label="קישור"
						render={(record: any) => (
							<IconButton
								onClick={(e) => {
									e.stopPropagation();
									navigator.clipboard.writeText(
										`https://d27ovugsb14jes.cloudfront.net/${record.videoUrl}`,
									);
									notify(
										<span className="bg-green-600 text-white px-4 py-2 rounded-lg text-sm">
											הקישור לסרטון <span className="font-black">{record.title}</span> הועתק ללוח
										</span>,
										{ type: 'success' },
									);
									return;
								}}
							>
								<BiLinkAlt />
							</IconButton>
						)}
					/>
				</Datagrid>
			)}
		</List>
	);
};
export default VodList;
