import { Box, Button, Input, MenuItem, Select, Stack, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import constants from '../constants';
const SelectMeetingByDate = (props: { onMeetingUpdate?: (id: string | null) => void; initValue: string | null }) => {
	const [date, setDate] = useState('');
	const [dayMeetings, setDayMeetings] = useState<any[]>([]);
	const [selectedMeeting, setSelectedMeeting] = useState<string | null>(props.initValue);

	useEffect(() => {
		if (dayMeetings) setSelectedMeeting(props.initValue);
	}, [props.initValue, dayMeetings]);
	useEffect(() => {
		const fetchMeetings = async () => {
			try {
				// setLoading(true);
				if (!props.initValue) {
					const res = await axios.get(`${constants.API_URL}/meeting/`, {
						params: {
							filter: { from_date: moment(date).startOf('day'), to_date: moment(date).endOf('day') },
						},
					});
					setDayMeetings(res.data);
				} else {
					const res = await axios.get<any>(`${constants.API_URL}/meeting/${props.initValue}/flat`);
					const lst = [];
					lst.push({ ...res.data, id: res.data._id });
					setDayMeetings(lst);
					setSelectedMeeting(props.initValue);
				}
			} catch (error) {
			} finally {
				// setLoading(false);
			}
		};
		fetchMeetings();
	}, [date, props.initValue]);

	return (
		<Box>
			<Stack gap={4}>
				<Box gap={2} display="flex" flexDirection="row" justifyContent="space-between">
					<Typography>תאריך המפגש:</Typography>
					<Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
				</Box>
				<Box>
					<Select
						style={{ minWidth: 300 }}
						value={selectedMeeting}
						onChange={(e) => {
							setSelectedMeeting(e.target.value);
						}}
					>
						{dayMeetings?.map((meeting: any) => (
							<MenuItem value={meeting._id}>{`${meeting.title} - ${meeting?.host
								?.map((x: any) => x.name)
								.join(',')}`}</MenuItem>
						))}
					</Select>
				</Box>
				<Box>
					<Button
						onClick={() => {
							if (props?.onMeetingUpdate) props?.onMeetingUpdate(selectedMeeting);
						}}
					>
						עדכון
					</Button>
				</Box>
			</Stack>
		</Box>
	);
};

export default SelectMeetingByDate;
