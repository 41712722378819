import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';

const NPSCard = ({ nps = 0 }: { nps?: number }) => {
	const npsValues = [
		{ value: Number.MIN_VALUE, color: 'red', text: 'Needs improvement' },
		{ value: 0, color: 'yellow', text: 'Good' },
		{ value: 30, color: 'green', text: 'Great' },
		{ value: 70, color: 'darkgreen', text: 'Excellent' },
	];
	const { color, text } =
		npsValues
			.slice()
			.reverse()
			.find((npsValue) => nps >= npsValue.value) || npsValues[0];
	return (
		<Card style={{ height: '100%', position: 'relative', textAlign: 'center' }}>
			<CardHeader
				title={
					<span className="text-center">
						{'Net Promoter Score'.split(' ').map((word) => (
							<span className="me-0">
								<span className="text-4xl font-bold">{word[0]}</span>
								{/* <span className="font-medium text-xl">{word.slice(1)}</span> */}
							</span>
						))}
					</span>
				}
				titleTypographyProps={{ fontSize: 30, fontWeight: 600 }}
			/>
			<CardContent>
				<div style={{ color }} className="text-center">
					<span className={`font-extrabold text-6xl text-center`}>{nps}</span>
					<div className="mt-2">
						<span className="text-lg font-medium">{text}</span>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default NPSCard;
