import axios from 'axios';
import { useCallback } from 'react';
import constants from '../../constants';
import useFetch from '../../hooks/useFetch';
import MissionsTable from './MissionsTable';
import PlanDetailsTable from './PlanDetailsTable';
import { PlanResponseProp } from './types.type';

const MemberProactivePlanForm = ({ member_id }: { member_id: string }) => {
	const data = useFetch<{
		data: PlanResponseProp;
	}>(`${constants.API_URL}/proactive/member/${member_id}`);

	const setAsCurrentWeek = useCallback(
		async (planId?: string, weekId?: string) => {
			await axios.post(`${constants.API_URL}/proactive/plan/${planId}/set-active-content`, { week: weekId });
			data.fetchAgain();
		},
		[data],
	);
	const reveal = useCallback(
		async (planId?: string, weekId?: string, revealed?: boolean) => {
			await axios.post(`${constants.API_URL}/proactive/plan/${planId}/set-revealed`, {
				week: weekId,
				revealed: revealed,
			});
			data.fetchAgain();
		},
		[data],
	);

	const setMissionDone = useCallback(
		async ({ mission_id, is_complete }: { mission_id: string; is_complete: boolean }) => {
			await axios.post(`${constants.API_URL}/proactive/plan/${member_id}/set-mission-done`, {
				mission_id,
				is_complete,
			});
			data.fetchAgain();
		},
		[data, member_id],
	);
	const resetPoints = useCallback(
		async (memberId?: string) => {
			await axios.post(`${constants.API_URL}/proactive/plan/${memberId}/reset-points`);
			data.fetchAgain();
		},
		[data],
	);
	const resetOnboarding = useCallback(async () => {
		await axios.post(`${constants.API_URL}/member/${member_id}/reset-onboarding`);
		data.fetchAgain();
	}, [member_id, data]);
	const regeneratePlan = useCallback(async () => {
		await axios.post(`${constants.API_URL}/proactive/member/${member_id}`);
		data.fetchAgain();
	}, [member_id, data]);

	const plan = data.data?.data;

	return (
		<div>
			<PlanDetailsTable
				member_id={member_id}
				plan={plan}
				regeneratePlan={regeneratePlan}
				resetOnboarding={resetOnboarding}
				resetPoints={resetPoints}
			/>
			<MissionsTable
				plan={plan}
				reveal={reveal}
				setAsCurrentWeek={setAsCurrentWeek}
				setMissionDone={setMissionDone}
			/>
		</div>
	);
};

export default MemberProactivePlanForm;
