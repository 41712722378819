import { BooleanField, CreateButton, Datagrid, ImageField, List, TextField, TopToolbar } from 'react-admin';

const MediaLinkList = () => {
	return (
		<List
			resource="media-links/all"
			actions={
				<TopToolbar>
					<CreateButton resource="media-links" />
				</TopToolbar>
			}
		>
			<Datagrid rowClick="edit" resource="media-links">
				<TextField source="title" />

				<TextField source="sub_title" />
				<BooleanField source="visible" />
				<TextField source="priority" />

				<TextField source="type" />

				<TextField source="link" />
				<ImageField source="thumbnailUrl" />
			</Datagrid>
		</List>
	);
};

export default MediaLinkList;
