import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
	ArrayInput,
	Button,
	Edit,
	ReferenceArrayInput,
	ReferenceInput,
	SelectArrayInput,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
} from 'react-admin';

const SectionEdit = () => {
	return (
		<Edit>
			<SimpleForm>
				<Grid2 container spacing={2}>
					<Grid2 xs={3}>
						<TextInput source="color" type="color" fullWidth />
					</Grid2>
					<Grid2 xs={3}>
						<TextInput type="color" source="textColor" fullWidth />
					</Grid2>
					<Grid2 xs={3}>
						<TextInput source="slug" disabled />
					</Grid2>
					<ArrayInput source="experts" label="מומחים במתחם" dir="rtl" sx={{ left: 0 }}>
						<SimpleFormIterator inline disableClear addButton={<Button label="הוספת מומחה למתחם" />}>
							<ReferenceInput source="expert._id" perPage={1000} reference="instructor/all">
								<SelectInput optionText={'name'} />
							</ReferenceInput>
							<Box width="100%" />
							<TextInput source="title.he" />
							<TextInput source="title.en" />
							<Box width="100%" />
							<TextInput source="action.he" />
							<TextInput source="action.en" />
							<Box width="100%" />
							<TextInput source="action_text.he" />
							<TextInput source="action_text.en" />
							<ReferenceArrayInput reference="media-links/category" source="podcasts_category">
								<SelectArrayInput optionText={'hebrew_name'} />
							</ReferenceArrayInput>
							<ReferenceArrayInput reference="media-links/category" source="video_category">
								<SelectArrayInput optionText={'hebrew_name'} />
							</ReferenceArrayInput>
							<ReferenceArrayInput reference="media-links/category" source="articles_category">
								<SelectArrayInput optionText={'hebrew_name'} />
							</ReferenceArrayInput>
							<ArrayInput source="tips" label="טיפים">
								<SimpleFormIterator inline addButton={<Button label="הוספת טיפ" />} disableClear>
									<TextInput source="text.he" />
									<TextInput source="text.en" />
									<TextInput source="link" />
								</SimpleFormIterator>
							</ArrayInput>
						</SimpleFormIterator>
					</ArrayInput>
				</Grid2>
			</SimpleForm>
		</Edit>
	);
};

export default SectionEdit;
