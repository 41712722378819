import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin';

const TrialUsersCreate = () => {
	return (
		<Create>
			<SimpleForm>
				<TextInput source="email" />
				<SelectInput
					choices={[
						{ id: 'trial', name: 'התנסות' },
						{ id: 'friend', name: 'חבר/ה' },
					]}
					source="type"
				/>
			</SimpleForm>
		</Create>
	);
};

export default TrialUsersCreate;
